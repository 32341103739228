import _ from 'lodash';
import React, { memo } from 'react';
import uniqid from 'uniqid';
import { FileType } from 'util/enums/Enum';
import ValidationControl from '../ValidationControl/ValidationControl';
import Textbox from './Textbox';

interface IProps {
  hideValidationControl?: boolean;
  name: string;
  value: string | number | null;
  dataType?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  isReadonly: boolean;
  isRequired?: boolean;
  onChangeWithValidation?: (event: any) => void;
  isValid?: boolean;
  validationMessage?: string;
  style?: any;
  precision?: number;
  appendText?: string;
  isDisabled?: boolean;
  fileType?: FileType;
}

export class TextboxControlObject extends React.Component<IProps> {
  private uid = uniqid();
  public render() {
    return (
      <table>
        <tbody>
          <tr>
            {!this.props.hideValidationControl ? (
              <td>
                <ValidationControl
                  {...this.props}
                  isValid={this.props.isValid !== undefined ? this.props.isValid : true}
                  message={this.props.validationMessage ? this.props.validationMessage : 'An input is required'}
                  uid={this.uid}
                />
              </td>
            ) : (
              ''
            )}
            <td>
              <Textbox {...this.props} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  return _.isEqual(_.omit(prevProps, _.functions(prevProps)), _.omit(nextProps, _.functions(nextProps)));
}

export default memo(TextboxControlObject, arePropsEqual);
