import { MethodType } from 'components/DropDown/MethodType';
import { fetchData, fetchDataReadWrite, getData, postData } from 'data/DataConnector';
import { isEqual } from 'lodash';
// import { isEqual } from 'lodash'
import React, { memo } from 'react';
import uniqid from 'uniqid';
import { shallowCompare } from 'util/helpers/helperFunctions';
import ValidationControl from '../../ValidationControl/ValidationControl';
import DropDownControl from '../DropdownControl';

const LOADING = 'Loading...';

export interface IProps {
  value: string;
  name?: string;
  isReadonly?: boolean;
  displayField: string;
  valueField: string;
  onChange?: (event: any) => void;
  methodType: MethodType;
  methodRoute?: string;
  parameters?: any;
  selectOption?: string;
  selectOptionValue?: string;
  isRequired?: boolean;
  isValid?: boolean;
  showAddModalLabel?: boolean;
  addModalLabelText?: string;
  onAddModalLabelClick?: () => any;
  onModalSave?: any;
  validationMessage?: string;
  isDisabled?: boolean;
  style?: any;
  hideValidationControl?: boolean;
  getDefaultValue?: (value: any, name?: string, data?: any) => void;
  getInitialSelectedText?: (selectedText: string) => void;
  methodName?: string;
  controllerName?: string;
  theme?: string;
  readWriteRequired?: boolean;
  dataTestId?: string;
  allowAdd?: boolean;
  onAddClick?: () => any;
  addTooltip?: string;
  allowEdit?: boolean;
  onEditClick?: () => any;
  editTooltip?: string;
  allowLiteralNull?: boolean;
}

interface IState {
  data: any[];
}

export class DropDownControlSqlObject extends React.Component<IProps, IState> {
  private uid = uniqid();
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      data: [{ [this.props.valueField]: '', [this.props.displayField]: LOADING }],
    };
  }

  public componentDidMount() {
    this.fetchData(
      this.props.methodRoute || '',
      this.props.parameters,
      this.props.methodType,
      this.props.controllerName,
      this.props.methodName,
    );
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      this.props.onModalSave !== prevProps.onModalSave ||
      prevProps.methodRoute !== this.props.methodRoute ||
      prevProps.methodType !== this.props.methodType ||
      !isEqual(prevProps.parameters, this.props.parameters) ||
      prevProps.selectOption !== this.props.selectOption ||
      prevProps.methodName !== this.props.methodName ||
      prevProps.controllerName !== this.props.controllerName
    ) {
      this.setState({
        ...this.state,
        data: [{ [this.props.valueField]: '', [this.props.displayField]: LOADING }],
      });
      this.fetchData(
        this.props.methodRoute || '',
        this.props.parameters,
        this.props.methodType,
        this.props.controllerName,
        this.props.methodName,
      );
    }
  }

  public render() {
    return (
      <table>
        <tbody>
          <tr>
            {!this.props.hideValidationControl ? (
              <td>
                <ValidationControl
                  {...this.props}
                  isValid={this.props.isValid !== undefined ? this.props.isValid : true}
                  message={this.props.validationMessage ? this.props.validationMessage : 'An input is required'}
                  uid={this.uid}
                />
              </td>
            ) : (
              <></>
            )}
            <td>
              <DropDownControl
                {...this.props}
                isDisabled={
                  this.state.data.length === 1 && this.state.data[0][this.props.displayField] === LOADING
                    ? true
                    : this.props.isDisabled
                }
                data={this.state.data}
                getDefaultValue={
                  this.state.data.length === 1 &&
                  (this.props.selectOption || this.state.data[0][this.props.displayField] === LOADING)
                    ? undefined
                    : this.props.getDefaultValue
                }
                getInitialSelectedText={
                  this.state.data.length === 1 &&
                  (this.props.selectOption || this.state.data[0][this.props.displayField] === LOADING)
                    ? undefined
                    : this.props.getInitialSelectedText
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  private fetchData(
    methodRoute: string,
    parameters: any,
    methodType: MethodType,
    controllerName?: string,
    methodName?: string,
  ) {
    if (controllerName && methodName) {
      if (this.props.readWriteRequired) {
        fetchDataReadWrite(controllerName, methodName, methodType, parameters).then(response => {
          this.processResponse(response.dataObject.data);
        });
      } else {
        fetchData(controllerName, methodName, methodType, parameters).then(response => {
          this.processResponse(response.dataObject.data);
        });
      }
    } else if (methodType === MethodType.Get) {
      getData(methodRoute, parameters, !this.props.readWriteRequired).then(response => {
        this.processResponse(JSON.parse(response).recordSets[0].data);
      });
    } else if (methodType === MethodType.Post) {
      postData(methodRoute, parameters).then(response => {
        this.processResponse(JSON.parse(response).recordSets[0].data);
      });
    }
  }

  private processResponse(resultSet: any) {
    const initialData = this.getInitialList(
      this.props.valueField,
      this.props.displayField,
      this.props.selectOption,
      this.props.selectOptionValue,
    );
    this.setState({
      data: [...initialData, ...resultSet],
    });
  }

  private getInitialList(
    valueField: string,
    displayField: string,
    selectOption: string = '',
    selectOptionValue: string = '',
  ) {
    let data: any[] = [];
    if (selectOption.indexOf(';') !== -1) {
      if (selectOptionValue.indexOf(';') !== -1) {
        const options: string[] = selectOption.split(';');
        const optionValues: string[] = selectOptionValue.split(';');
        if (options.length === optionValues.length) {
          options.forEach((element, index) => {
            data.push({
              [valueField]: optionValues[index],
              [displayField]: element,
            });
          });
        }
      }
    } else {
      if (selectOption) {
        data.push({
          [valueField]: selectOptionValue,
          [displayField]: selectOption,
        });
      }
    }
    return data;
  }
}

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }
  if (oldProps.methodType === newProps.methodType) {
    if (oldProps.methodType === MethodType.Post) {
      if (!shallowCompare(oldProps.parameters, newProps.parameters)) {
        return false;
      } else {
        delete oldProps.parameters;
        delete newProps.parameters;
      }
    }
  }

  if ('onAddModalLabelClick' in oldProps) {
    delete oldProps.onAddModalLabelClick;
  }
  if ('onAddModalLabelClick' in newProps) {
    delete newProps.onAddModalLabelClick;
  }

  if ('onModalSave' in oldProps) {
    delete oldProps.onModalSave;
  }
  if ('onModalSave' in newProps) {
    delete newProps.onModalSave;
  }

  if ('onAddClick' in oldProps) {
    delete oldProps.onAddClick;
  }
  if ('onAddClick' in newProps) {
    delete newProps.onAddClick;
  }

  if ('onEditClick' in oldProps) {
    delete oldProps.onEditClick;
  }
  if ('onEditClick' in newProps) {
    delete newProps.onEditClick;
  }

  if ('style' in oldProps) {
    delete oldProps.style;
  }
  if ('style' in newProps) {
    delete newProps.style;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }
  return shallowCompare(oldProps, newProps);
}

export default memo(DropDownControlSqlObject, arePropsEqual);
