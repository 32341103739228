import aadAuth from '@virtus/common/auth/sagas/AADLogin';
import { runSagas } from '@virtus/common/sagas/runSagas';

/**
 * add all sagas here
 */
const sagas = [...aadAuth];

/**
 * Call this from createStore to run all sagas as their own root.
 *
 * Will Restart each saga if it catches an error and console.error it
 * This stops one error bubbling to parent and killing all sagas
 *
 * @param sagaMiddleware pass in from redux init
 */
export const runAllSagas = (sagaMiddleware: any) => {
  runSagas(sagaMiddleware, sagas);
};
