import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const SystemSetupIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M8 0L1.5 2.91v4.363c0 4.036 2.773 7.81 6.5 8.727 3.727-.916 6.5-4.69 6.5-8.727V2.909L8 0zM6.556 11.636l-2.89-2.909 1.019-1.025 1.87 1.876 4.76-4.793 1.018 1.033-5.777 5.818z"
      />
    </g>
  </SvgIcon>
);
