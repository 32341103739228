import { Location, LocationContext, navigate, Router } from '@reach/router';
import { User } from '@virtus/common/auth/aadWrapper';
import { SHOW_ERROR } from 'actions/ErrorActions/errorActionTypes';
import * as GlobalActions from 'actions/globalActions';
import { IS_BACK_CLICKED } from 'actions/SearchCriteria/searchCriteriaActionTypes';
import 'App.css';
import AuthenticationHandlerGenesis, { AuthenticationRenderHandlerProps } from 'AuthenticationHandlerGenesis';
import DealSelector from 'components/DealSelector';
import { MethodType } from 'components/DropDown/MethodType';
import PageLoadingIndicator from 'components/PageLoadingIndicator/PageLoadingIndicator';
import configureHeader from 'config/headerConfig';
import config, { aadConfigType } from 'config/index';
import { generateRoute, getPath } from 'config/routeConfig';
import { RouteConstAccessDenied } from 'constants/RouteConstants';
import { fetchData, setUserName } from 'data/DataConnector';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { ControllerNameEnum } from 'util/enums/Enum';
import { getDateTimeKey, getJson } from 'util/helpers/helperFunctions';
import DealList from 'views/DealList';
import { logoutAad } from '@virtus/common/auth/aadWrapper';

//const AuthenticationHandler = React.lazy(() => import('@virtus/common/auth/AuthenticationHandler'));
const VirtusTheme = React.lazy(() =>
  import('@virtus/components').then(module => ({
    default: module.VirtusTheme,
  })),
);
const Header = React.lazy(() => import('@virtus/components/page/Header'));
const AccessDenied = React.lazy(() => import('views/AccessDenied/AccessDenied'));

export const encLoginId = btoa('loginId');

export const decodeUserLoginId = () => {
  const decLoginId = localStorage.getItem(encLoginId);
  return decLoginId && atob(decLoginId);
};

interface IProps {
  isBackClicked: any;
  dateFormat: any;
  menu: any;
  placeholder: any;
  setMenu: any;
  setUser: any;
  setUserFullName: any;
  username: string;
  currentId: number;
  companyId: number;
  entityId: number;
  route: any;
  sideMenu: any;
  showError: any;
  searchCriteriaDict: any;
  setDefaultPath: any;
  initMenu: any;
  setUserGroups: any;
  userConfig: aadConfigType;
}

let routeConfig: any = (
  <Router className={'Router'}>
    <DealList path="/DealList" />
  </Router>
);

class App extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.props.dateFormat();
    this.props.placeholder();
  }

  public componentDidMount() {
    window.onpopstate = async () => {
      this.props.isBackClicked(true);
    };
    window.onbeforeunload = () => {
      this.props.isBackClicked(true);
    };
    window.onload = async () => {
      this.props.isBackClicked(false);
    };
  }
  public render() {
    const headerConfig = configureHeader(this.props.route);
    const sideMenuConfig = configureHeader(this.props.sideMenu);
    return (
      <Suspense
        fallback={
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--bg)',
            }}
          >
            <PageLoadingIndicator />
          </div>
        }
      >
        <VirtusTheme>
          <AuthenticationHandlerGenesis
            config={this.props.userConfig}
            render={({ isAuthenticated, login, user, isValidUser, error }: AuthenticationRenderHandlerProps) => {
              if (error || !isValidUser) {
                this.logoutUser();
                return <></>;
              }
              const userFullName = this.getUserName(user, isValidUser);
              return isAuthenticated && headerConfig.routes.subroutes.length > 0 ? (
                <div className="App">
                  <Location>
                    {(location: LocationContext) => {
                      routeConfig = generateRoute(location.location.pathname, this);
                      return (
                        <Header
                          data-testid={location.location.pathname}
                          key={getDateTimeKey()}
                          misc={
                            isAuthenticated ? (
                              <DealSelector entityId={this.props.entityId} userId={this.props.username} />
                            ) : undefined
                          }
                          {...headerConfig}
                          sideMenuRoutes={sideMenuConfig.routes}
                          path={location.location.pathname}
                          isLoggedIn={isAuthenticated}
                          onLogin={login}
                          topRightButtonText={userFullName}
                          topRightMenuItems={[
                            /* { text: 'Profile', onClick: () => { this.userProfile() } }, */
                            {
                              onClick: () => {
                                this.logoutUser();
                              },
                              text: 'Logout',
                            },
                          ]}
                          onAppButtonClick={(path: any) => {
                            this.props.isBackClicked(false);
                            navigate(path);
                          }}
                          onRouteChange={(path: string) => {
                            const newPath = getPath(path.slice(0), location.location.pathname, true);
                            routeConfig = undefined;
                            this.props.isBackClicked(false);
                            navigate(newPath);
                            this.forceUpdate();
                          }}
                        />
                      );
                    }}
                  </Location>

                  {isAuthenticated ? routeConfig : <></>}
                </div>
              ) : (
                <React.Fragment>
                  {window.location.pathname !== RouteConstAccessDenied && (
                    <div style={{ width: '100%', height: '100%' }}>
                      <PageLoadingIndicator />
                    </div>
                  )}
                  <Router className={'Router'}>
                    <AccessDenied path={RouteConstAccessDenied} />
                  </Router>
                </React.Fragment>
              );
            }}
          />
        </VirtusTheme>
      </Suspense>
    );
  }

  private initializeMenu = (userId: string) => {
    if (userId !== undefined && userId !== '') {
      Promise.all([setUserName(userId)]).then(async () => {
        Promise.all([
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaMenuXmlList', MethodType.Post, {
            userId,
            RecordSetNumber: 1,
          }),
          // fetchData(ControllerNameEnum.CommonFunctions, 'CdosaMenuXmlList', MethodType.Post, { userId, RecordSetNumber: 2 }),
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaMenuXmlList', MethodType.Post, {
            userId,
            RecordSetNumber: 2,
            entityId: 1,
          }),
          fetchData(ControllerNameEnum.CommonFunctions, 'CdosaUserGroupList', MethodType.Get, [
            { name: 'userId', value: userId },
          ]),
          fetchData('Sql', 'EntityList', MethodType.Post, { userId }),
        ]).then(([defaultPathResponse, dealMenuResponse, userGroups]) => {
          const dealMenu = getJson(dealMenuResponse.dataObject.data);
          if (dealMenu !== undefined && dealMenu.m_level1 && dealMenu.m_level1[0]) {
            this.props.initMenu([defaultPathResponse.dataObject.data, dealMenu]);
          } else {
            if (window.location.pathname !== RouteConstAccessDenied) {
              window.location.pathname = RouteConstAccessDenied;
            }
          }
          this.props.setUserGroups(userGroups.dataObject.data);
        });
      });
    }
  };

  private getUserName(user: User | any, isValidUser: any) {
    if (!user || !user.idToken) {
      return '';
    }

    const userName: { given_name: string; family_name: string } = user.idTokenClaims;
    const userEmail: string = user.idTokenClaims?.emails ?? '';
    let userId: string = isValidUser?.UserName ?? '';

    // Use extension_Username as the userId, if extension_Username is present in the token
    // and it is not empty and not same as the user email.
    const userExtensionId: string = user.idTokenClaims?.extension_Username ? user.idTokenClaims.extension_Username : '';
    if (userExtensionId !== '' && userExtensionId !== userEmail) {
      userId = userExtensionId;
    }

    if (this.props.username !== userId) {
      Promise.all([
        this.props.setUser(userId),
        this.props.setUserFullName(userName.given_name + ' ' + userName.family_name),
        setUserName(userId),
      ]).then(() => {
        this.initializeMenu(userId);
        this.props.setMenu(window.location.pathname);
      });
    }

    return userName.given_name + ' ' + userName.family_name;
  }

  private logoutUser() {
    logoutAad();
    localStorage.removeItem(encLoginId);
    localStorage.removeItem('FacilityXRefSearchText');
    localStorage.removeItem('FacilityXRefSearchType');
  }
}

const mapStateToProps = (stateFromStore: any) => {
  return {
    companyId: stateFromStore.globalRdcr.companyId,
    currentId: stateFromStore.globalRdcr.currentId,
    entityId: stateFromStore.globalRdcr.entityId,
    menu: stateFromStore.globalRdcr.menu,
    route: stateFromStore.globalRdcr.route,
    searchCriteriaDict: stateFromStore.searchCriteriaRdcr.searchCriteriaDict,
    sideMenu: stateFromStore.globalRdcr.sideMenu,
    username: stateFromStore.globalRdcr.username,
  };
};

const mapDispatchActionToProps = (dispatch: any) => {
  return {
    dateFormat: () => dispatch({ type: GlobalActions.GET_DATE_FORMAT }),
    initMenu: (menu: any[]) => dispatch({ type: GlobalActions.INIT_MENU, data: menu }),
    isBackClicked: (isBackClicked: boolean) => dispatch({ type: IS_BACK_CLICKED, data: isBackClicked }),
    placeholder: () => dispatch({ type: GlobalActions.GET_DATE_PLACEHOLDER }),
    setDefaultPath: (menuData: any) => dispatch({ type: GlobalActions.SET_DEFAULT_PATH, data: menuData }),
    setMenu: (menu: string, command?: string) => dispatch({ type: GlobalActions.SET_MENU, data: menu, command }),
    setUser: (userId: string) => dispatch({ type: GlobalActions.SET_USER, data: userId }),
    setUserFullName: (userName: string) => dispatch({ type: GlobalActions.SET_USER_FULLNAME, data: userName }),
    setUserGroups: (data: any[]) => dispatch({ type: GlobalActions.SET_USER_GROUPS, data }),
    showError: (errorMessage: string) => dispatch({ type: SHOW_ERROR, data: { showError: true, errorMessage } }),
  };
};
export default connect(mapStateToProps, mapDispatchActionToProps)(App);
