import React, { memo } from 'react';
import { getTrimmedValue, shallowCompare } from 'util/helpers/helperFunctions';
import './DropDownControl.css';
import { Add, Edit } from '@mui/icons-material';
export interface IProps {
  theme?: string;
  value: string;
  name?: string;
  isReadonly?: boolean;
  data?: any[];
  displayField: string;
  valueField: string;
  onChange?: (event: any) => void;
  showAddModalLabel?: boolean;
  addModalLabelText?: string;
  onAddModalLabelClick?: () => any;
  onModalSave?: any;
  style?: any;
  getDefaultValue?: (value: any, name?: string, data?: any) => void;
  getInitialSelectedText?: (selectedText: string) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  hideBlankValue?: boolean;
  dataTestId?: string;
  allowAdd?: boolean;
  onAddClick?: () => any;
  addTooltip?: string;
  allowEdit?: boolean;
  onEditClick?: () => any;
  editTooltip?: string;
  allowLiteralNull?: boolean;
}

export const DropdownControl = (props: IProps): any => {
  let style = {
    color: props.isReadonly ? 'grey' : 'white',
    height: 22,
    ...props.style,
  };
  if (props.isDisabled || props.isLoading) {
    style = {
      ...style,
      opacity: '0.4',
    };
  }
  const lightStyle = {
    color: 'black',
    height: 22,
    ...props.style,
  };
  const labelStyle = {
    ...props.style,
  };

  let selected: number = 0;
  if (props.data !== undefined) {
    props.data.every((item, index) => {
      if (getTrimmedValue(item[props.valueField]) && getTrimmedValue(props.value)) {
        if (item[props.valueField].toString().trim() === props.value.toString().trim()) {
          selected = index;
          return false;
        }
      }
      return true;
    });
  }

  if (
    props.data !== undefined &&
    props.data.length > 0 &&
    (!props.value || props.value === null) &&
    props.getDefaultValue
  ) {
    props.getDefaultValue(props.data[0][props.valueField], props.name, {
      selectedText: props.data[0][props.displayField],
    });
  } else if (props.data && props.getInitialSelectedText) {
    props.getInitialSelectedText(props.data[selected][props.displayField]);
  }

  let options: any = [];
  if (props.data !== undefined) {
    options = props.data.map((x: any, index: number) => {
      const value = getTrimmedValue(x[props.valueField], props.allowLiteralNull);
      return (
        <option
          key={index.toString()}
          value={value}
          style={{
            display: `${value === '' && props.hideBlankValue ? 'none' : ''}`,
          }}
        >
          {getTrimmedValue(x[props.displayField])}
        </option>
      );
    });
  }

  if (props.isLoading) {
    options = [
      React.createElement('option', {
        value: '',
        children: 'Loading...',
        key: 0,
      }),
    ];
  }

  return (
    <React.Fragment>
      <table>
        <tbody>
          <tr>
            <td style={{ padding: '0px' }} className={'virtus-select'}>
              {!props.isReadonly ? (
                <select
                  data-testid={props.dataTestId ? props.dataTestId : props.name}
                  className={props.theme === 'light' ? 'select-light' : 'select-dark'}
                  style={props.theme === 'light' ? lightStyle : style}
                  value={props.value ? props.value.toString().trim() : ''}
                  name={props.name}
                  disabled={props.isDisabled || props.isLoading}
                  onChange={props.onChange}
                >
                  {options}
                </select>
              ) : (
                <span style={{ paddingLeft: 6 }}>
                  {props.data !== undefined && props.data[selected] ? props.data[selected][props.displayField] : ''}
                </span>
              )}
            </td>
            <td>
              {props.allowAdd && (
                <a href="#self" title={props.addTooltip}>
                  <Add onClick={props.onAddClick} style={{ height: '16px', width: '16px' }} />
                </a>
              )}
              {props.allowEdit && (
                <a href="#self" title={props.editTooltip}>
                  <Edit onClick={props.onEditClick} style={{ height: '16px', width: '16px' }} />
                </a>
              )}
            </td>
          </tr>
          {props.showAddModalLabel ? (
            <tr>
              <td>
                <label style={labelStyle} className="hyperlink" onClick={props.onAddModalLabelClick}>
                  {props.addModalLabelText}
                </label>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

function arePropsEqual(prevProps: IProps, nextProps: IProps) {
  const oldProps = {
    ...prevProps,
  };
  const newProps = {
    ...nextProps,
  };
  if (oldProps.data !== undefined && newProps.data !== undefined) {
    if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
      return false;
    }
  }

  if ('data' in oldProps) {
    delete oldProps.data;
  }
  if ('data' in newProps) {
    delete newProps.data;
  }

  if ('theme' in oldProps) {
    delete oldProps.theme;
  }
  if ('theme' in newProps) {
    delete newProps.theme;
  }

  if ('getDefaultValue' in oldProps) {
    delete oldProps.getDefaultValue;
  }
  if ('getDefaultValue' in newProps) {
    delete newProps.getDefaultValue;
  }

  if ('onChange' in oldProps) {
    delete oldProps.onChange;
  }
  if ('onChange' in newProps) {
    delete newProps.onChange;
  }

  if ('onAddModalLabelClick' in oldProps) {
    delete oldProps.onAddModalLabelClick;
  }
  if ('onAddModalLabelClick' in newProps) {
    delete newProps.onAddModalLabelClick;
  }

  if ('onAddClick' in oldProps) {
    delete oldProps.onAddClick;
  }
  if ('onAddClick' in newProps) {
    delete newProps.onAddClick;
  }

  if ('onEditClick' in oldProps) {
    delete oldProps.onEditClick;
  }
  if ('onEditClick' in newProps) {
    delete newProps.onEditClick;
  }

  return shallowCompare(oldProps, newProps);
}

export default memo(DropdownControl, arePropsEqual);
