import ActionButton, { ButtonType } from 'components/Buttons/ActionButton';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import 'components/hoc/PopupModal/ModalFooter.css';
import React from 'react';
import { getTrimmedValue } from 'util/helpers/helperFunctions';

interface IProps {
  disableDeleteButton?: boolean;
  onDeleteClicked?: any;
  isComponentLoading: boolean;
  onCancelClicked: () => void;
  onOperatioConfirmed: () => any;
  componentType: ComponentType;
  footerText?: string;
  showOperationConfirmedBtn?: boolean;
  showDeleteButton?: boolean;
  btnText?: string;
  hideSearchButton?: boolean;
  disableSaveButton?: boolean;
  operationCancelledBtnText?: string;
  onCopyClicked?: () => void;
  hideCancel?: boolean;
  onYesClicked?: any;
  onNoClicked?: any;
}

const footerStyle = {
  background: 'var(--modal-header-bg)',
  border: '0px',
  color: 'var(--white)',
};

const ModalFooter = (props: IProps) => {
  return (
    <React.Fragment>
      <div className="modal-footer" style={footerStyle}>
        {DisplayFooterText(props)}
        {!props.hideCancel && GetCancelButton(props)}
        {props.showOperationConfirmedBtn !== false && GetOperationButton(props)}
      </div>
    </React.Fragment>
  );
};

function DisplayFooterText(props: IProps) {
  if (props.componentType === ComponentType.SelectList) {
    return <label style={{ marginBottom: 0, marginRight: 'auto' }}>{props.footerText}</label>;
  } else if (props.footerText) {
    return <label style={{ marginBottom: 0, marginRight: 'auto' }}>{props.footerText}</label>;
  }
}

function GetCancelButton(props: IProps) {
  if (!(props.componentType === ComponentType.Alert || props.componentType === ComponentType.SelectList)) {
    return (
      <ActionButton
        buttonText={props.operationCancelledBtnText}
        buttonType={props.operationCancelledBtnText === 'Close' ? ButtonType.Default : ButtonType.Cancel}
        onClick={props.onCancelClicked}
      />
    );
  } else if (props.componentType === ComponentType.Alert && props.onCopyClicked) {
    return <ActionButton buttonText={'Close'} buttonType={ButtonType.Cancel} onClick={props.onOperatioConfirmed} />;
  }
}

function GetOperationButton(props: IProps) {
  if (!props.isComponentLoading) {
    switch (props.componentType) {
      case ComponentType.Delete:
        return <ActionButton onClick={props.onOperatioConfirmed} buttonType={ButtonType.Delete} />;
      case ComponentType.Edit:
        return (
          <React.Fragment>
            {props.showDeleteButton ? (
              <ActionButton
                disabled={props.disableDeleteButton}
                buttonType={ButtonType.Delete}
                onClick={props.onDeleteClicked}
              />
            ) : (
              <></>
            )}
            <ActionButton
              disabled={props.disableSaveButton}
              buttonType={
                getTrimmedValue(props.btnText) === 'Copy' || getTrimmedValue(props.btnText) === 'Calculate'
                  ? getTrimmedValue(props.btnText) === 'Copy'
                    ? ButtonType.Copy
                    : ButtonType.Calculate
                  : ButtonType.Default
              }
              buttonText={getTrimmedValue(props.btnText) === '' ? 'Save' : props.btnText}
              onClick={props.onOperatioConfirmed}
            />
          </React.Fragment>
        );
      case ComponentType.Alert:
        return props.onCopyClicked ? (
          <ActionButton buttonText="Copy & Close" buttonType={ButtonType.Copy} onClick={props.onCopyClicked} />
        ) : (
          <ActionButton buttonText="Close" buttonType={ButtonType.Default} onClick={props.onOperatioConfirmed} />
        );
      case ComponentType.SelectList:
        return <ActionButton buttonText="Close" buttonType={ButtonType.Default} onClick={props.onOperatioConfirmed} />;
      case ComponentType.Search:
        return props.hideSearchButton ? (
          <></>
        ) : (
          <ActionButton
            buttonType={ButtonType.Default}
            buttonText={getTrimmedValue(props.btnText) === '' ? 'Search' : props.btnText}
            onClick={props.onOperatioConfirmed}
          />
        );
      case ComponentType.Confirm:
        return <ActionButton buttonType={ButtonType.Confirm} onClick={props.onOperatioConfirmed} />;
      case ComponentType.DataDictionary:
        return <ActionButton buttonType={ButtonType.Default} buttonText="Submit" onClick={props.onOperatioConfirmed} />;
      case ComponentType.YesOrNo:
        return (
          <React.Fragment>
            <ActionButton buttonType={ButtonType.Default} buttonText="Yes" onClick={props.onYesClicked} />
            <ActionButton buttonType={ButtonType.Default} buttonText="No" onClick={props.onNoClicked} />
          </React.Fragment>
        );
    }
  }
}

export default ModalFooter;
