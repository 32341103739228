interface IState {
  selectedCountryCode: string;
  selectedCountryName: string;
}

const defaultState: IState = {
  selectedCountryCode: '',
  selectedCountryName: '',
};

export const countriesReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'SET_COUNTRY':
      return {
        ...state,
        selectedCountryCode: action.data.country_code,
        selectedCountryName: action.data.country_name,
      };
    default:
      return state;
  }
};
