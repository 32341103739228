/** colors used in apps */
export const colors = {
  accent: '#0d83c9',
  bg: '#28333c',
  bgDark: '#1c252e',
  bgHeader: '#f8f8f8',
  bgLight: '#ffffff',
  dataGridExpandedRow: '#d6edf1',
  dataGridHeaderText: 'rgba(0, 0, 0, 0.54)',
  dataGridInnerBorder: '#ddd',
  dataGridSelectedRow: '#0073ea',
  deleteBtn: '#f20000',
  error: '#af0319',
  errorDark: '#7b1427',
  errorLight: '#c0392b',
  inspectorSubtitle: 'rgba(255,255,255,.5)',
  light: '#f57051',
  link: '#16b3d3',
  reset: '#08679e',
  resetButton: '#08679e',
  subtitle: 'rgba(255,255,255,.5)',
  text: '#ffffff',
  textDark: '#000',
};

export const fontFamily = 'NunitoSans';

/** various common css sizes (eg font size) */
export const variables = {
  cellHeight: '48px',
  checkboxFontSize: '16px',
  colWidth: '150px',
  headerHeight: '40px',
  headerIconFontSize: '30px',
  iconColWidth: '40px',
};
