import { DialogContent, DialogContentText } from '@mui/material';
import { MethodType } from 'components/DropDown/MethodType';
import DxPopupModal from 'components/hoc/PopupModal/DxPopupModal';
import ModalContent from 'components/hoc/PopupModal/ModalContent';
import { ComponentType } from 'components/hoc/PopupModal/ModalEnum';
import ModalFooter from 'components/hoc/PopupModal/ModalFooter';
import ModalHeader from 'components/hoc/PopupModal/ModalHeader';
import { AlertModal } from 'components/Modals/AlertModal/AlertModal';
import DOMPurify from 'dompurify';
import 'components/Modals/DeleteModal/DeleteModal.css';
import {
  dbConstActionMessage,
  dbConstActionMessageCode,
  dbConstOperationDetails,
  dbConstOperationMessage,
  dbConstOperationPrimaryKey,
  dbConstOperationResultCode,
} from 'constants/DbConstants';
import { deleteData, postData } from 'data/DataConnector';
import { ScrollView } from 'devextreme-react';
import React from 'react';
import { connect } from 'react-redux';
import { getErrorMessage, isEqualProps, isStringNullOrWhitespace } from 'util/helpers/helperFunctions';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

export interface IProps {
  isModalOpen: boolean;
  isDtoRequired: boolean;
  header?: string;
  message?: string;
  methodRoute: string;
  parameters: any;
  onCloseModal: any;
  onDeleteData: any;
  username: string;
  confirmationRequired?: boolean;
  methodType?: MethodType;
  deleteAlertMessage?: string;
}

interface IState {
  isLoading: boolean;
  showDelete: boolean;
  showAlert: boolean;
  alertMessage: string;
  showConfirmation: boolean;
  confirmationMessage: string[];
}

class DeleteModal extends React.Component<IProps, IState> {
  private closeDeleteModal = true;
  constructor(props: IProps) {
    super(props);
    this.state = {
      alertMessage: '',
      confirmationMessage: [],
      isLoading: false,
      showAlert: false,
      showConfirmation: false,
      showDelete: this.props.isModalOpen,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!isEqualProps(prevProps, this.props)) {
      this.setState({
        showDelete: this.props.isModalOpen,
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <AlertModal
          isModalOpen={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onCloseModal={this.closeAlertModal}
        />

        <DxPopupModal
          isModalOpen={this.state.showDelete}
          modalHeader={
            <ModalHeader
              isComponentLoading={this.state.isLoading}
              componentHeader="Are you sure?"
              onCloseClicked={this.props.onCloseModal}
              componentType={ComponentType.Delete}
            />
          }
        >
          <ScrollView
            scrollByContent={true}
            bounceEnabled={false}
            direction={'both'}
            showScrollbar={'always'}
            scrollByThumb={true}
          >
            <DialogContent style={{ padding: '0px' }}>
              <DialogContentText
                component={'div'}
                style={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                <div className="delete-modal-content">
                  <ModalContent isLoading={this.state.isLoading}>
                    {
                      <React.Fragment>
                        {this.props.deleteAlertMessage ? <span>{this.props.deleteAlertMessage}</span> : ''}
                        <span>
                          {this.props.message ? (
                            <b
                              dangerouslySetInnerHTML={{
                                __html:DOMPurify.sanitize('You are about to delete ' + this.props.message,)
                              }}
                            />
                          ) : (
                            <b>You are about to remove information.</b>
                          )}
                        </span>
                        <span>Are you sure you want to continue?</span>
                      </React.Fragment>
                    }
                  </ModalContent>
                </div>
              </DialogContentText>
            </DialogContent>
            <br />
            <br />
          </ScrollView>
          <div>
            <ModalFooter
              isComponentLoading={this.state.isLoading}
              onCancelClicked={this.props.onCloseModal}
              onOperatioConfirmed={this.onModalFooterDelete}
              componentType={ComponentType.Delete}
            />
          </div>
        </DxPopupModal>

        <ConfirmationModal
          isModalOpen={this.state.showConfirmation}
          messages={this.state.confirmationMessage}
          onCloseModal={this.closeAlertModal}
          onConfirm={this.onConfirmedClicked}
        />
      </React.Fragment>
    );
  }

  private onModalFooterDelete = () => {
    this.onDelete(
      this.props.methodRoute,
      this.props.parameters,
      this.props.confirmationRequired === true ? true : false,
    );
  };

  private onConfirmedClicked = () => {
    this.onDelete(this.props.methodRoute, this.props.parameters, false);
  };

  private showAlertModal(message: string) {
    this.setState({
      ...this.state,
      alertMessage: message,
      isLoading: false,
      showAlert: true,
      showDelete: !this.closeDeleteModal,
    });
  }

  private closeAlertModal = () => {
    this.setState(
      {
        ...this.state,
        isLoading: false,
        showAlert: false,
        showConfirmation: false,
      },
      () => {
        if (this.closeDeleteModal) {
          this.props.onCloseModal();
        }
      },
    );
  };

  private onDelete(methodRoute: string, parameters: any, confirmationRequired: boolean) {
    if (this.props.isDtoRequired) {
      parameters = {
        ...parameters,
        operationConfirmed: confirmationRequired === true ? 0 : 1,
        silentMode: 0,
        userId: this.props.username,
      };
    } else {
      parameters += `&operationConfirmed=${confirmationRequired === true ? 0 : 1}`;
    }

    this.setState({
      ...this.state,
      isLoading: true,
      showConfirmation: false,
    });
    if (this.props.methodType === MethodType.Post) {
      postData(methodRoute, parameters)
        .then(response => {
          this.validateResponse(response, confirmationRequired);
        })
        .catch(error => {
          this.showAlertModal(getErrorMessage(error));
        });
    } else {
      deleteData(methodRoute, parameters, this.props.isDtoRequired)
        .then(response => {
          this.validateResponse(response, confirmationRequired);
        })
        .catch(error => {
          this.showAlertModal(getErrorMessage(error));
        });
    }
  }

  private validateResponse = (response: any, confirmationRequired: boolean) => {
    if (JSON.parse(response).recordSets[0].data && JSON.parse(response).recordSets[0].data.length > 0) {
      const operationResultCode = JSON.parse(response).recordSets[0].data[0][dbConstOperationResultCode];
      const operationMessage = JSON.parse(response).recordSets[0].data[0][dbConstOperationMessage];
      const operationDetails = JSON.parse(response).recordSets[0].data[0][dbConstOperationDetails];
      const operationPrimaryKey = JSON.parse(response).recordSets[0].data[0][dbConstOperationPrimaryKey];

      if (operationResultCode >= 10 || !isStringNullOrWhitespace(operationMessage)) {
        let message = operationMessage;
        if (message === undefined || message === null) {
          message = '';
        }
        if (!isStringNullOrWhitespace(operationDetails)) {
          message += '\n' + operationDetails;
        }

        const actionMessageCode = JSON.parse(response).recordSets[0].data[0][dbConstActionMessageCode];
        const actionMessage = JSON.parse(response).recordSets[0].data[0][dbConstActionMessage];

        if (confirmationRequired && !isStringNullOrWhitespace(actionMessageCode)) {
          switch (actionMessageCode) {
            case 'ARE_YOU_SURE':
              this.setState({
                confirmationMessage: [operationMessage, operationDetails],
                isLoading: false,
                showAlert: false,
                showConfirmation: true,
              });
              break;
            case 'DEL_NOT_ALLOWED':
              if (operationMessage) {
                message = operationMessage;
              }
              message += actionMessage
                ? (operationDetails ? '\n' + operationDetails : '') + '\n\n' + actionMessage
                : '\n\nDelete operation is not allowed.';
              this.closeDeleteModal = false;
              this.showAlertModal(message);
              break;
            case 'DELETE_NOT_ALLOWED':
              if (operationMessage) {
                message = operationMessage;
              }
              message += actionMessage
                ? (operationDetails ? '\n' + operationDetails : '') + '\n\n' + actionMessage
                : '\n\nDelete operation is not allowed.';
              this.closeDeleteModal = false;
              this.showAlertModal(message);
              break;
            case 'CHECK_TRY_AGAIN':
              if (operationMessage) {
                message = operationMessage;
              }
              message += '\n\nPlease check your data and try again';
              this.closeDeleteModal = false;
              this.showAlertModal(message);
              break;
            case 'OPERATION_ABORTED':
              if (operationMessage) {
                message = operationMessage;
              }
              message += actionMessage ? '\n\n' + actionMessage : '';
              this.closeDeleteModal = false;
              this.showAlertModal(message);
              break;
          }

          return;
        } else {
          this.showAlertModal(message);
        }
      } else {
        this.setState(
          {
            isLoading: false,
            showAlert: false,
            showDelete: false,
          },
          () => this.props.onDeleteData(operationPrimaryKey),
        );
      }
    } else {
      this.setState(
        {
          isLoading: false,
          showAlert: false,
          showDelete: false,
        },
        () => this.props.onDeleteData(),
      );
    }
  };
}

const mapStateToProps = (stateFromStore: any) => {
  return {
    username: stateFromStore.globalRdcr.username,
  };
};

const ConnectedDeleteModal = connect(mapStateToProps)(DeleteModal);

export { ConnectedDeleteModal as DeleteModal };
