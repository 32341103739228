//Syncing with staging
import { authActions, authSelectors, IDPUser, RootState } from '@virtus/common/auth/reducer';
import React, { createContext, useEffect } from 'react';
import { connect } from 'react-redux';

// Auth Handler

type ReduxProps = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isValidUser: boolean;
  user: IDPUser | null;
  error: any;
};

type OwnProps = {
  render: (state: any) => React.ReactNode;
  config: any;
};

type ReduxDispatch = {
  initializeAuth: (arg: any) => void;
  login: (arg?: any) => void;
  logout: (arg?: any) => void;
};

type AuthenticationHandlerProps = {
  isAuthenticated: boolean;
  isValidUser: any;
  user: IDPUser | null;
  error?: any;
};

export type AuthenticationRenderHandlerProps = AuthenticationHandlerProps & ReduxProps & OwnProps & ReduxDispatch;

export const AuthContext = createContext({});

const AuthenticationHandlerGenesis = ({
  render,
  isAuthenticated,
  login,
  initializeAuth,
  logout,
  isInitialized,
  user,
  config,
  isValidUser,
  error,
}: AuthenticationRenderHandlerProps) => {
  useEffect(() => {
    if (isInitialized && !user && !error) {
      login(config);
    }
  });
  if (!isInitialized) {
    initializeAuth(config);
  }

  return (
    <>
      {error &&
        render({
          isAuthenticated,
          login,
          logout,
          user,
          isValidUser,
          error,
        })}
      {user &&
        isInitialized &&
        isAuthenticated &&
        render({
          isAuthenticated,
          login,
          logout,
          user,
          isValidUser,
          error,
        })}
    </>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  isInitialized: authSelectors.getStatus(state) === 'initialized',
  user: authSelectors.getUser(state),
  isAuthenticated: authSelectors.isAuthenticated(state),
  isValidUser: authSelectors.isValidUser(state),
  error: authSelectors.loginError(state),
});

const mapDispatchToProps = {
  initializeAuth: authActions.aadInitialize,
  login: authActions.aadLogin,
  logout: authActions.aadLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandlerGenesis);
