import { PrimaryButton } from '@virtus/components/Buttons';
import { VirtusIcon } from '@virtus/components/icons';
import { Page } from '@virtus/components/page';
import App, { decodeUserLoginId, encLoginId } from 'App';
import config, { getAADConfig } from 'config/index';
import TextBox from 'devextreme-react/text-box';
import GenesisIcon from 'icons/GenesisIcon';
import { useCallback, useEffect, useState } from 'react';
import './Login.css';
import * as S from './Login.style';

const DOLOGIN_FAIL = 'DoLogin Fail';

const Login = () => {
  const [loginId, setLoginId] = useState('');
  const [haveError, setHaveError] = useState(false);
  const [userConfig, setUserConfig] = useState(getAADConfig(''));

  useEffect(() => {
    if (localStorage.getItem(DOLOGIN_FAIL) === 'true') {
      localStorage.removeItem(DOLOGIN_FAIL);
    }
    const localLoginId = decodeUserLoginId();
    if (localLoginId !== undefined && localLoginId !== null && loginId === '') {
      setLoginId(localLoginId);
      setUserConfig(getAADConfig(localLoginId));
    }
  }, []);

  const onProceedClicked = useCallback((genesisUserName: string) => {
    if (genesisUserName.trim().length) {
      setLoginId(genesisUserName);
      setHaveError(false);
      localStorage.setItem(encLoginId, btoa(genesisUserName));
      setUserConfig(getAADConfig(genesisUserName));
    } else {
      setHaveError(true);
      setLoginId('');
    }
  }, []);

  if (decodeUserLoginId()) {
    return <App userConfig={userConfig} />;
  } else {
    return (
      <Page style={{ paddingTop: '0px' }} appName={config.appName} pageTitle="User Login">
        <div style={{ width: '100%' }}>
          <S.HeaderMenu>
            <VirtusIcon style={{ margin: '10px', width: '100px' }} />
            <S.AppButton>
              <GenesisIcon /> Genesis
            </S.AppButton>
          </S.HeaderMenu>
          <S.MainContent>
            <>
              <div className="form">
                <div className="dx-fieldset">
                  <div className="dx-fieldset-header">User Login</div>
                  <div className="dx-field" style={{ marginLeft: '40px', marginRight: '40px' }}>
                    <div style={{ textAlign: 'right', paddingRight: '10px' }} className="dx-field-label">
                      Username:
                    </div>
                    <TextBox
                      className="dx-field-value"
                      name="username"
                      mode="text"
                      showClearButton={true}
                      value={loginId}
                      valueChangeEvent="keyup"
                      onValueChanged={data => setLoginId(data.value)}
                      onEnterKey={() => onProceedClicked(loginId)}
                    />
                  </div>
                </div>
              </div>
              {haveError && (
                <>
                  <S.ErrorContent> UserName field is required. </S.ErrorContent>
                  <br />
                </>
              )}
              {localStorage.getItem(DOLOGIN_FAIL) === 'true' && (
                <>
                  <S.ErrorContent>
                    {' '}
                    There was a problem processing your request. Please try again later or contact your system
                    administrator.{' '}
                  </S.ErrorContent>
                  <br />
                </>
              )}
              <S.ActionContent>
                <PrimaryButton className="btn-primary" onClick={() => onProceedClicked(loginId)}>
                  Proceed
                </PrimaryButton>
              </S.ActionContent>
              <S.DisclaimerText>
                This is an FIS Application environment, which may be accessed and used only for official business by by
                authorized personnel. Unauthorized access or use of this environment is prohibited and may violators to
                administrative, and/or criminal, civil action. Users (authorized or unauthorized) have no explicit or
                implicit expectation of privacy. All information on this environment may be intercepted, monitored,
                recorded, read, copied, audited, inspected and disclosed by and to authorized personnel.
              </S.DisclaimerText>
            </>
          </S.MainContent>
        </div>
      </Page>
    );
  }
};

export default Login;
