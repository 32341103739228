import DropDownControlCodeTableObject from 'components/DropDown/DropDownControlCodeTable/DropDownControlCodeTableObject';
import DropdownControlObject from 'components/DropDown/DropdownControlObject';
import DropDownControlSqlObject from 'components/DropDown/DropDownControlSql/DropDownControlSqlObject';
import DropDownControlSqlRow from 'components/DropDown/DropDownControlSql/DropDownControlSqlRow';
import { MethodType } from 'components/DropDown/MethodType';
import { EditModal } from 'components/Modals/EditModal/EditModal';
import PageMessageControl from 'components/PageMessageControl/PageMessageControl';
import TextAreaControlObject from 'components/TextAreaControl/TextAreaControlObject';
import TextboxControlObject from 'components/TextboxControl/TextboxControlObject';
import TextboxControlRow from 'components/TextboxControl/TextboxControlRow';
import { fetchData, IGetParameterObject, saveData } from 'data/DataConnector';
import {
  OnChange,
  OnChangeWithValidation,
  OnCurrencyChange,
  OnCurrencyChangeWithValidation,
  OnSaveValidation,
  ValidationModel,
  ValidationType,
} from 'helpers/ValidationHelper';
import React from 'react';
import { ControllerNameEnum } from 'util/enums/Enum';
import { PageMessageIconType } from 'util/enums/PageMessageIconTypeEnum';
import { handleAsteriskConditions } from 'util/helpers/CommonComponents';
import { getTrimmedValue, getUsername, objectToCamel, toCamel } from 'util/helpers/helperFunctions';
import { EditBaseClass } from 'views/EditBaseClass';

interface ICdosysConfigDealValuePut {
  userId: string;
  entityId: number;
  configCode: string;
  configValue: string;
}

interface IProps {
  configCode: string;
  systemMode: string;
  entityId?: number;
  onClose: any;
  onSave: any;
}

interface IState {
  isLoading: boolean;
  modalHeader: string;
  configInformation: any;
  lookupType: string;
}

class SettingValueEdit extends EditBaseClass<IProps, IState> {
  private putStoredProcedureName: string;
  private parametersDetails: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      configInformation: {},
      isLoading: true,
      lookupType: '',
      modalHeader: 'Config Value Edit',
    };
    this.putStoredProcedureName = 'CdosysConfigDealValuePut';
    this.onChangeHandler = (event: any) => OnChange(this, this.state.configInformation, event);
    this.onCurrencyChangeHandler = (event: any) => OnCurrencyChange(this, this.state.configInformation, event);
    this.onCurrencyChangeWithValidationHandler = (event: any) =>
      OnCurrencyChangeWithValidation(this, this.state.configInformation, this.validationData, event);
    this.onChangeWithValidationHandler = (event: any) =>
      OnChangeWithValidation(this, this.state.configInformation, this.validationData, event);
    this.validationData = {
      configValue: new ValidationModel([ValidationType.RequiredField], 'Config Value', true),
    };
  }

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    return (
      <EditModal
        isLoading={this.state.isLoading}
        isModalOpen={true}
        modalHeader={this.state.modalHeader}
        onCloseModal={this.props.onClose}
        onSave={this.onSave}
      >
        <table style={{ width: '40vw' }}>
          <tbody>
            {getTrimmedValue(this.props.systemMode) === 'DEAL' && (
              <DropDownControlSqlRow
                label="Deal Name"
                name="entityId"
                value={getTrimmedValue(this.props.entityId)}
                displayField="deal_nickname"
                valueField="entity_id"
                methodType={MethodType.Post}
                methodRoute="Sql/EntityList"
                parameters={{}}
                isReadonly={true}
                onChange={() => {
                  /** */
                }}
              />
            )}
            <TextboxControlRow
              label="Setting Name"
              value={this.state.configInformation.configDescription}
              name="configDescription"
              isReadonly={true}
              isRequired={false}
              onChange={undefined}
            />

            {this.state.lookupType === '' && this.state.configInformation.valueType === 'SYSL' ? (
              <tr>
                <td colSpan={2}>{this.getControl(this.state.configInformation)}</td>
              </tr>
            ) : (
              <tr>
                <td>
                  Config Value
                  {Boolean(this.state.configInformation.valueRequired) &&
                    handleAsteriskConditions(this.validationData.configValue.IsValid)}
                </td>
                <td>{this.getControl(this.state.configInformation)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </EditModal>
    );
  }

  private getControl(configInformation: any) {
    if (configInformation !== undefined) {
      const valueRequired = Boolean(configInformation.valueRequired);
      const readOnly = Boolean(configInformation.readOnly);
      switch (configInformation.dataType) {
        case 'BOOLEAN':
          return (
            <DropdownControlObject
              data={
                valueRequired
                  ? [
                      { key: '1', value: 'Yes' },
                      { key: '0', value: 'No' },
                    ]
                  : [
                      { key: '', value: '(Not Specified)' },
                      { key: '1', value: 'Yes' },
                      { key: '0', value: 'No' },
                    ]
              }
              value={configInformation.configValue}
              name={'configValue'}
              isRequired={valueRequired}
              isReadonly={readOnly}
              displayField={'value'}
              valueField={'key'}
              onChange={this.onChangeHandler}
            />
          );
        case 'CURRENCY':
          return (
            <TextboxControlObject
              isValid={this.validationData.configValue.IsValid}
              validationMessage={this.validationData.configValue.Message}
              value={configInformation.configValue}
              name={'configValue'}
              isRequired={valueRequired}
              isReadonly={readOnly}
              onChange={valueRequired ? this.onCurrencyChangeWithValidationHandler : this.onCurrencyChangeHandler}
              dataType="CURRENCY"
            />
          );
        case 'NUMERIC':
        case 'PERCENT':
          return (
            <TextboxControlObject
              isValid={this.validationData.configValue.IsValid}
              validationMessage={this.validationData.configValue.Message}
              value={configInformation.configValue}
              name={'configValue'}
              isRequired={valueRequired}
              isReadonly={readOnly}
              onChange={valueRequired ? this.onChangeWithValidationHandler : this.onChangeHandler}
              dataType={configInformation.dataType}
            />
          );
        case 'TEXT':
          switch (configInformation.valueType) {
            case 'FTXT':
              return (
                <TextAreaControlObject
                  isValid={this.validationData.configValue.IsValid}
                  validationMessage={this.validationData.configValue.Message}
                  value={configInformation.configValue}
                  name={'configValue'}
                  isRequired={valueRequired}
                  isReadonly={readOnly}
                  onChange={valueRequired ? this.onChangeWithValidationHandler : this.onChangeHandler}
                />
              );
            case 'SYSL':
              return this.state.lookupType === '' ? (
                <PageMessageControl
                  iconType={PageMessageIconType.Information}
                  label="The Lookup Type for this setting has not been defined. To define the Lookup Type, go to System Administration >> System Settings Manager."
                />
              ) : (
                <DropDownControlCodeTableObject
                  isRequired={valueRequired}
                  isReadonly={readOnly}
                  name={'configValue'}
                  value={configInformation.configValue}
                  codeType={this.state.lookupType}
                  selectOption={valueRequired ? '-- Select Config Value --' : '(None)'}
                  isValid={this.validationData.configValue.IsValid}
                  validationMessage={this.validationData.configValue.Message}
                  onChange={valueRequired ? this.onChangeWithValidationHandler : this.onChangeHandler}
                />
              );
            case 'CSTD':
              return configInformation.lookupDataSource === '' ? (
                <PageMessageControl
                  iconType={PageMessageIconType.Information}
                  label={
                    'The Lookup Data Source for this setting has not been defined. To define the Lookup Data Source, go to System Administration >> System Settings Manager.'
                  }
                />
              ) : (
                <DropDownControlSqlObject
                  name="configValue"
                  methodType={
                    this.parametersDetails.methodName === 'EntityTransTypeList' ? MethodType.Post : MethodType.Get
                  }
                  methodName={this.parametersDetails.methodName}
                  controllerName={this.parametersDetails.controllerName}
                  valueField={this.parametersDetails.valueField}
                  displayField={this.parametersDetails.displayField}
                  isRequired={valueRequired}
                  isReadonly={readOnly}
                  parameters={this.parametersDetails.parameters}
                  onChange={this.onChangeHandler}
                  value={configInformation.configValue}
                  selectOption={valueRequired ? '-- Select Config Value --' : '(None)'}
                />
              );
          }
          break;
        default:
          return <></>;
      }
    }
  }

  private getEndpointDetails = async (aDSComponents: any) => {
    const spNameAndParameters = aDSComponents[0].split(' ');
    const methodName = toCamel(spNameAndParameters[0].replace('dbo.', ''));
    let parametersDetails: any;
    switch (methodName) {
      case 'cdobizIdentifierList':
        parametersDetails = {
          controllerName: 'GlobalInfo',
          methodName: 'CdobizIdentifierList',
          parameters: [
            {
              name: 'aggregationLevel',
              value: spNameAndParameters[1].replaceAll("'", ''),
              sequence: 1,
            },
          ],
        };
        break;
      case 'cdobizJurisdictionList':
        parametersDetails = {
          controllerName: 'Asset',
          methodName: 'CdoBizJurisdictionList',
          parameters: {},
        };
        break;
      case 'cdobizSearchOptionList':
        parametersDetails = {
          controllerName: 'Generic',
          methodName: 'CdoBizSearchOptionList',
          parameters: [
            {
              name: 'aggLevel',
              value: spNameAndParameters[1].replaceAll("'", ''),
              sequence: 1,
            },
          ],
        };
        break;
      case 'entityTransTypeList':
        parametersDetails = {
          controllerName: 'AssetFacilityProperties',
          methodName: 'EntityTransTypeList',
          parameters: { transTypeCat: 'IP', cashTransType: 'R' },
        };
        break;
    }

    return {
      ...parametersDetails,
      valueField: aDSComponents[1],
      displayField: aDSComponents[2],
    };
  };

  private async fetchCdosysLookupTypeData(lookupTypeId: string) {
    let lookupType = '';
    const result = await fetchData('CommonFunctions', 'CdosysLookupTypeGet', MethodType.Post, {
      lookupTypeId,
    });
    if (result.dataObject.data.length > 0) {
      lookupType = getTrimmedValue(result.dataObject.data[0].lookup_type);
    }
    return lookupType;
  }

  private async fetchData() {
    let response;
    if (getTrimmedValue(this.props.systemMode) === 'G' || getTrimmedValue(this.props.systemMode) === 'GLBL') {
      const param: IGetParameterObject[] = [{ name: 'configCode', value: this.props.configCode, sequence: 0 }];
      response = await fetchData('TransactionMatching', 'CdosysConfigValueGet', MethodType.Get, param);
      this.putStoredProcedureName = 'CdosysConfigValuePut';
      if (response.dataObject.data.length > 0) {
        response.dataObject.data[0].configValue = response.dataObject.data[0].deal_config_value
          ? response.dataObject.data[0].deal_config_value
          : response.dataObject.data[0].system_config_value;
      }
    } else if (getTrimmedValue(this.props.systemMode) === 'DEAL') {
      const param = {
        configCode: this.props.configCode,
        entityId: this.props.entityId,
      };
      response = await fetchData('Generic', 'CdosysConfigDealValueGet', MethodType.Post, param);
      if (response.dataObject.data.length > 0) {
        response.dataObject.data[0].configValue = response.dataObject.data[0].deal_config_value
          ? response.dataObject.data[0].deal_config_value
          : response.dataObject.data[0].system_config_value;
      }
      this.putStoredProcedureName = 'CdosysConfigDealValuePut';
    }
    if (response && response.dataObject.data.length > 0) {
      const lookupType = await this.fetchCdosysLookupTypeData(response.dataObject.data[0].lookup_type_id);
      if (response.dataObject.data[0].value_type === 'CSTD') {
        const aDSComponents = response.dataObject.data[0].lookup_data_source.split(';');
        this.parametersDetails = await this.getEndpointDetails(aDSComponents);
      }
      this.setState({
        configInformation: objectToCamel(response.dataObject.data[0]),
        lookupType,
      });
    }

    this.setState({
      isLoading: false,
    });
  }

  private onSave = async () => {
    const param: ICdosysConfigDealValuePut = {
      configCode: this.state.configInformation.configCode,
      configValue:
        getTrimmedValue(this.state.configInformation.configValue) === ''
          ? null
          : this.state.configInformation.configValue,
      entityId: this.props.entityId || 0,
      userId: getUsername(),
    };
    let isValidated = true;
    if (Boolean(this.state.configInformation.valueRequired)) {
      isValidated = OnSaveValidation(this, param, this.validationData);
    }
    if (isValidated) {
      const response = await saveData(ControllerNameEnum.CommonFunctions, this.putStoredProcedureName, param);
      if (!response.hasConfirmationMessage && !response.hasError) {
        this.props.onSave();
      }
    }
  };
}

export default SettingValueEdit;
