import * as React from 'react';

interface IProps {
  validationColor?: string;
  component: any;
  description: string;
  isRequired: boolean;
  isReadOnly?: boolean;
  style?: any;
  showAddModalLabel?: boolean;
}

function AddControlRow({ component, description, isRequired, style, showAddModalLabel, validationColor }: IProps) {
  const newStyle = { ...{ whiteSpace: 'nowrap', padding: '0px' }, ...style };
  return (
    <tr className={'control-row'}>
      <td id={showAddModalLabel ? 'addModalLabel' : ''} style={newStyle} align="left">
        <span
          data-testid={description}
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        &nbsp;
        {isRequired && (
          <span>
            <b style={{ color: validationColor ? validationColor : 'red' }}>*</b>
          </span>
        )}
      </td>
      <td style={{ padding: '0px' }}>{component}</td>
    </tr>
  );
}

export default AddControlRow;
