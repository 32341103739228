export const SET_USER = 'SET_USER';
export const SET_MENU = 'SET_MENU';
export const APPEND_MENU = 'APPEND_MENU';
export const APPEND_MENU_TO_PARENT = 'APPEND_MENU_TO_PARENT';
export const SET_DEFAULT_PATH = 'SET_DEFAULT_PATH';
export const SET_CURRENT_ID = 'SET_CURRENT_ID';
export const SET_HEIGHT_TO_REMOVE = 'SET_HEIGHT_TO_REMOVE';
export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const GET_DATE_FORMAT = 'GET_DATE_FORMAT';
export const GET_DATE_PLACEHOLDER = 'GET_DATE_PLACEHOLDER';
export const REMOVE_SUBROUTES = 'REMOVE_SUBROUTES';
export const SET_USER_FULLNAME = 'SET_USER_FULLNAME';
export const SET_ENTITY_ID = 'SET_ENTITY_ID';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const INIT_MENU = 'INIT_MENU';
export const APPEND_TAB_MENU = 'APPEND_TAB_MENU';
export const SET_READONLY_HEADER = 'SET_READONLY_HEADER';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
