import { ILinkedColumn } from '@virtus/components/DxDataGrid/DxDataGrid';
import { IDataObject } from 'data/DataConnector';
import React from 'react';
import { getTrimmedValue } from 'util/helpers/helperFunctions';

export const onEditorPreparing = (event: any) => {
  if (event.parentType === 'filterRow' && event.dataType === 'boolean') {
    event.editorOptions.valueExpr = 'filterValue';
    event.editorOptions.displayExpr = 'filterText';
    event.editorOptions.searchEnabled = false;
    event.editorOptions.dataSource = [
      { filterValue: null, filterText: '(All)' },
      { filterValue: true, filterText: 'Yes' },
      { filterValue: false, filterText: 'No' },
      { filterValue: 'null', filterText: '(Blanks)' },
    ];
  }
};

export const fixBooleanColumns = (dataObject: IDataObject) => {
  dataObject.schema.forEach((column: any) => {
    if (column.DataTypeName === 'boolean') {
      const columnName = column.ColumnName;
      dataObject.data.forEach((row: any) => {
        let value = getTrimmedValue(row[columnName]);
        if (value === 'Yes' || value === '1' || value === 'true' || value === 1) {
          value = true;
        } else if (value === 'No' || value === '0' || value === 'false' || value === 0) {
          value = false;
        } else if (value === '' || value === null) {
          value = 'null';
        }
        row[columnName] = value;
      });
    }
  });
};

export const fixColumnKeyName = (name: string): string => {
  return name.replace(new RegExp('\\.', 'g'), '');
};

export const addDummyColumn = (dataObject: IDataObject) => {
  let dummyColumnRequired: boolean = true;
  dataObject.schema.forEach(col => {
    if (!col.ColumnName.includes('_') || (col.Hidden !== undefined && !col.Hidden)) {
      if (col.DataTypeName === 'varchar' || col.DataTypeName === 'char' || col.DataTypeName === 'boolean') {
        dummyColumnRequired = false;
      }
    }
  });
  if (dummyColumnRequired) {
    dataObject.schema.push({
      BaseColumnName: '__dummyColumn__',
      Caption: '',
      ColumnName: '__dummyColumn__',
      ColumnSize: 300,
      DataTypeName: 'varchar',
      Hidden: false,
      NumericPrecision: 255,
      NumericScale: 255,
    });

    dataObject.data.forEach(row => {
      row = { ...row, __dummyColumn__: '' };
    });
  }
};

export const generateLink = (cellData: any, linkedColumns?: ILinkedColumn[]) => {
  const columnName = cellData.column.dataField;
  const filtered = linkedColumns?.filter((lCol: ILinkedColumn) => lCol.columnName === columnName);
  if (
    filtered &&
    filtered.length &&
    ((filtered[0].condition && filtered[0].condition(cellData.data)) || !filtered[0].condition)
  ) {
    const onClick = filtered[0].onClick;
    return (
      <a href="#self" onClick={(event: any) => onClick(cellData.data, event)}>
        {cellData.displayValue}
      </a>
    );
  }
  return <>{cellData.displayValue}</>;
};
