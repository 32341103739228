import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DealSetupIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 0H17V16H1z" transform="translate(-1)" />
      <path
        fill="#FFF"
        d="M3.99 7L0 11l3.99 4v-3H11v-2H3.99V7zM18 5l-3.99-4v3H7v2h7.01v3L18 5z"
        transform="translate(-1)"
      />
    </g>
  </SvgIcon>
);
