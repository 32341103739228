import { Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import React from 'react';

interface IStyledTabProps {
  label: string;
  icon?: React.ReactElement<any>;
}

interface IStyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  children: React.ReactNode;
}

const TabsCollection = (props: IStyledTabsProps) => (
  <Tabs variant="scrollable" scrollButtons={true} aria-label="Tabs" {...props} />
);

const StyledTabs = withStyles(TabsCollection, () => ({
  flexContainer: {
    alignItems: 'flex-end',
  },
  indicator: {
    backgroundColor: 'var(--default-blue)',
    visibility: 'hidden',
  },
  root: {
    backgroundColor: 'transparent',
    borderBottom: 'solid 3px var(--default-blue)',
    display: 'inline-block',
    maxWidth: '100%',
    minHeight: 27,
    width: 'auto',
  },
  scrollButtons: {
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
    backgroundColor: 'transparent',
    color: 'white',
    height: 25,
    width: 16,
  },
}));
const CustomTab = (props: IStyledTabProps) => <Tab disableRipple={true} {...props} />;

const StyledTab = withStyles(CustomTab, () => ({
  labelContainer: {
    padding: '0px',
  },
  root: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    backgroundColor: 'rgba(255,255,255,0.14)',
    color: 'var(--text)',
    fontFamily: 'Nunito Sans',
    fontSize: 'var(--main-font-size)',
    height: '10px',
    margin: '0px 2px 0px 2px',
    minHeight: '20px',
    minWidth: '50px',
    opacity: 1,
    padding: '10px',
    textTransform: 'none',
  },
  selected: {
    backgroundColor: 'var(--default-blue)',
    minHeight: '25px',
  },
  wrapper: {
    flexDirection: 'row-reverse',
  },
}));

interface ITabInfo {
  tabId: number;
  path: string;
  text: string;
  icon?: React.ReactElement<any>;
}

interface IProps {
  dataSource: ITabInfo[];
  selectedIndex?: number;
  onChange: (value: any) => void;
}

export const PageTab = (props: IProps) => {
  const [value, setValue] = React.useState(props.selectedIndex || 0);
  const dataSource = props.dataSource || [];
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    const selectedVal = dataSource[newValue];
    props.onChange(selectedVal);
  };
  return (
    <StyledTabs value={value} onChange={handleChange}>
      {dataSource.map((row: ITabInfo) => (
        <StyledTab key={row.tabId} icon={row.icon} label={row.text} />
      ))}
    </StyledTabs>
  );
};
