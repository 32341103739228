import { CLOSE_TOAST, SHOW_ERROR, SHOW_ERRORS, SHOW_MESSAGE, SHOW_TOAST } from 'actions/ErrorActions/errorActionTypes';
import uniqid from 'uniqid';
import { getTrimmedValue } from 'util/helpers/helperFunctions';

interface IToastDetail {
  message: string;
  navigateTo: string;
  type: string;
  id: string;
  headerText?: string;
}

interface IState {
  showError: boolean;
  errorMessage: string[];
  showInformation: boolean;
  errorHeader: string;
  showToast: boolean;
  toastDetails: IToastDetail[];
  currentToast?: IToastDetail;
  allowAlertCopy: boolean;
  showLayoutEdit: boolean;
  layoutInfo: any;
}

const defaultState: IState = {
  allowAlertCopy: false,
  errorHeader: '',
  errorMessage: ['Something went wrong'],
  layoutInfo: {},
  showError: false,
  showInformation: false,
  showLayoutEdit: false,
  showToast: false,
  toastDetails: [],
};

export const errorReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        errorMessage: [action.data.errorMessage],
        showError: action.data.showError,
      };
    case SHOW_ERRORS:
      return {
        ...state,
        errorMessage: action.data.errorMessages,
        showError: action.data.showError,
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        allowAlertCopy: Boolean(action.data.allowCopy),
        errorHeader: getTrimmedValue(action.data.header),
        errorMessage: [action.data.errorMessage],
        showInformation: action.data.showMessage,
      };
    case SHOW_TOAST: {
      const uid = uniqid();
      const currentToast: IToastDetail = {
        headerText: action.data.headerText,
        id: uid,
        message: action.data.toastMessage,
        navigateTo: action.data.navigateTo,
        type: action.data.toastType,
      };
      return {
        ...state,
        currentToast,
        showToast: action.data.showToast,
        toastDetails: [...state.toastDetails, currentToast],
      };
    }
    case CLOSE_TOAST:
      const filteredToastDetails = state.toastDetails.filter((details: IToastDetail) => details.id !== action.data);
      return {
        ...state,
        currentToast: undefined,
        showToast: state.toastDetails.length !== filteredToastDetails.length,
        toastDetails: filteredToastDetails,
      };
    case 'SHOW_LAYOUT_EDIT':
      return {
        ...state,
        layoutInfo: action.data,
        showLayoutEdit: true,
      };
    case 'HIDE_LAYOUT_EDIT':
      return {
        ...state,
        showLayoutEdit: false,
      };
    default:
      return state;
  }
};
