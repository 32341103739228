// Contains Route constants

export const RouteConstAccessDenied = '/AccessDenied/AccessDenied';
export const RouteConstError = '/Error/Error';
export const RouteConstFacilityPaymentScheduleDel = '/Facility/FacilityPaymentScheduleDel';
export const RouteConstFacilityTransactions = '/GlobalInfo/LoanFacilities/Facility_Transactions/Transactions';
export const RouteConstDataIntegrityFacilityMissingLiens = '/GlobalInfo/DataIntegrity';
export const RouteConstIssuerBorrowerClientInfoRatings = '/GlobalInfo/ClientInfo/IssuersBorrowers/Ratings';
export const RouteConstRestructuresOldFacility = '/GlobalInfo/Transactions/Restructures/OldFacility';
export const RouteConstCusipOldIssueDetails = '/GlobalInfo/Transactions/CUSIP_Exchange/OldIssue';
export const RouteConstCusipExchanges = '/GlobalInfo/Transactions/CUSIP_Exchange/Purchase';
export const RouteConstCusipExchangesSell = '/GlobalInfo/Transactions/CUSIP_Exchange/Sell';
export const RouteConstSLFacilityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/SaleLots/SaleLots/Main';
export const RouteConstSLFacilityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/SaleLots/SaleLots/Main';
export const RouteConstSLSecurityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Properties/SaleLots/SaleLots/Main';
export const RouteConstSLSecurityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Transactions/SaleLots/SaleLots/Main';
export const RouteConstSLEquityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Properties/SaleLots/SaleLots/Main';
export const RouteConstSLEquityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Transactions/SaleLots/SaleLots/Main';
export const RouteConstFacilityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/Main';
export const RouteConstFacilityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/Main';
export const RouteConstEquityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Properties/Main';
export const RouteConstEquityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Transactions/Main';
export const RouteConstSecurityPropertiesMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Properties/Main';
export const RouteConstSecurityTransactionsMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Transactions/Main';
export const RouteConstFacilityPropertiesLoans =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/Loans';
export const RouteConstFacilityTransactionsLoans =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/Loans';
export const RouteConstFacilityPropertiesSaleLotsLoans =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/SaleLots/SaleLots/Loans';
export const RouteConstFacilityTransactionsSaleLotsLoans =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/SaleLots/SaleLots/Loans';
export const RouteConstFacilityTransactionsInterestPayments =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/InterestPayments';
export const RouteConstFacilityPropertiesLoansTransactions =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/Loans/Loans/Transactions';
export const RouteConstFacilityTransactionsLoansTransactions =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/Loans/Loans/Transactions';
export const RouteConstFacilityPropertiesLoansMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/Loans/Loans/Main';
export const RouteConstFacilityTransactionsLoansMain =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/Loans/Loans/Main';
export const RouteConstEquityPropertiesSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Properties/SaleLots';
export const RouteConstEquityTransactionsSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityEquity_Transactions/SaleLots';
export const RouteConstFacilityPropertiesSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/SaleLots';
export const RouteConstFacilityTransactionsSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/SaleLots';
export const RouteConstSecurityPropertiesSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Properties/SaleLots';
export const RouteConstSecurityTransactionsSaleLots =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilitySecurity_Transactions/SaleLots';
export const RouteConstFacilityPropertiesCommitmentHistory =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Properties/CommitmentHistory';
export const RouteConstFacilityTransactionsCommitmentHistory =
  '/DealMaintenance/LiabilitiesCapitalStructure/LiabilityFacility_Transactions/CommitmentHistory';
export const RouteConstAssetEquityPropertiesMain = '/DealMaintenance/Assets/AssetEquity_Properties/Main';
export const RouteConstAssetEquityTransactionsMain = '/DealMaintenance/Assets/AssetEquity_Transactions/Main';
export const RouteConstEquityPropertiesPurchaseLots = '/DealMaintenance/Assets/AssetEquity_Properties/PurchaseLots';
export const RouteConstEquityTransactionsPurchaseLots = '/DealMaintenance/Assets/AssetEquity_Transactions/PurchaseLots';
export const RouteConstAssetFacilityTransactions = '/DealMaintenance/Assets/AssetFacility_Transactions/Main';
export const RouteConstNotFound = '/NotFound/NotFound404';
export const RouteConstAssetFacilityPropertiesMain = '/DealMaintenance/Assets/AssetFacility_Properties/Main';
export const RouteConstAssetCommitmentHistory = 'DealMaintenance/Assets/AssetFacility_Properties/CommitmentHistory';
