const apiRoutes: any[] = [
  //   {
  //     menu_id: '218',
  //     parent_menu_id: '0',
  //     menu_caption: 'Global Information',
  //     menu_type: 'M',
  //     url_full_path: '/Apps/',
  //     url_path: 'GlobalInfo',
  //     display_order: '4',
  //     parm: '',
  //     sub_menu: '1',
  //     global_level: '1',
  //     m_level2: [
  //       {
  //         menu_id: '219',
  //         parent_menu_id: '218',
  //         menu_caption: 'Issues',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'Issues',
  //         url_page: '/IssueSearch.asp',
  //         url_target: '_self',
  //         display_order: '2',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '220',
  //         parent_menu_id: '218',
  //         menu_caption: 'Loan Facilities',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'LoanFacilities',
  //         url_page: '/Navigate.asp',
  //         url_target: '_self',
  //         display_order: '4',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '222',
  //         parent_menu_id: '218',
  //         menu_caption: 'Issuers and Borrowers',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'IssuersBorrowers',
  //         url_page: '/IssuerSearch.asp',
  //         url_target: '_self',
  //         display_order: '5',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '224',
  //         parent_menu_id: '218',
  //         menu_caption: 'Banks and Counterparties',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'BanksCounterparties',
  //         url_page: '/BanksSearch.asp',
  //         url_target: '_self',
  //         display_order: '6',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '378',
  //         parent_menu_id: '218',
  //         menu_caption: 'Countries',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'Countries',
  //         url_page: '/Ratings/CountryRatingsList.asp',
  //         url_target: '_self',
  //         display_order: '7',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '1379',
  //         parent_menu_id: '218',
  //         menu_caption: 'Country Region Designation',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'CountryRegion',
  //         url_page: '/CountryRegion_List.asp',
  //         url_target: '_self',
  //         display_order: '8',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '1380',
  //         parent_menu_id: '218',
  //         menu_caption: 'Company Asset Types',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'CompanyAssetTypes',
  //         url_page: '/CompanyAssetType_List.asp',
  //         url_target: '_self',
  //         display_order: '9',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '225',
  //         parent_menu_id: '218',
  //         menu_caption: 'Payees',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'Payees',
  //         url_page: '/PayeesSearch.asp',
  //         url_target: '_self',
  //         display_order: '10',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '384',
  //         parent_menu_id: '218',
  //         menu_caption: 'Transactions',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'Transactions',
  //         display_order: '11',
  //         parm: '',
  //         sub_menu: '1',
  //         global_level: '1',
  //         m_level3: [
  //           {
  //             menu_id: '739',
  //             parent_menu_id: '384',
  //             menu_caption: 'Global Transaction Search',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'GlobalTransactions',
  //             url_page: '/GlobalTransactions.asp',
  //             url_target: '_self',
  //             display_order: '0',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '740',
  //             parent_menu_id: '384',
  //             menu_caption: 'Cross Deal Asset and Liability Transaction Search',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'CrossDealTransactions',
  //             url_page: '/CrossDealTransactions.asp',
  //             url_target: '_self',
  //             display_order: '1',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '741',
  //             parent_menu_id: '384',
  //             menu_caption: 'Cross Deal Cash Transaction Search',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'CrossDealCashTransactions',
  //             url_page: '/CrossDealCashTransactions.asp',
  //             url_target: '_self',
  //             display_order: '2',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '742',
  //             parent_menu_id: '384',
  //             menu_caption: 'Record Cash Activity',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'ApplyCashReceipts',
  //             url_page: '/ApplyCashReceipts.asp',
  //             url_target: '_self',
  //             display_order: '3',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '1310',
  //             parent_menu_id: '384',
  //             menu_caption: 'Restructures',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'Restructures',
  //             url_page: '/Restructures.asp',
  //             url_target: '_self',
  //             display_order: '4',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '1392',
  //             parent_menu_id: '384',
  //             menu_caption: 'CUSIP Exchange',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/Transactions/',
  //             url_path: 'CUSIP_Exchange',
  //             url_page: '/CUSIP_Exchange.asp',
  //             url_target: '_self',
  //             display_order: '5',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //         ],
  //       },
  //       {
  //         menu_id: '766',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Ratings XRefs',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'CrossRef',
  //         display_order: '11',
  //         parm: '',
  //         sub_menu: '1',
  //         global_level: '1',
  //         m_level3: [
  //           {
  //             menu_id: '893',
  //             parent_menu_id: '766',
  //             menu_caption: 'Country Cross Reference',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/CrossRef/',
  //             url_path: 'XrefCountries',
  //             url_page: '/XRefCountrySearch.asp',
  //             url_target: '_self',
  //             display_order: '1',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '768',
  //             parent_menu_id: '766',
  //             menu_caption: 'Issuer Cross Reference',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/CrossRef/',
  //             url_path: 'XRefIssuers',
  //             url_page: '/XRefIssuerSearch.asp',
  //             url_target: '_self',
  //             display_order: '2',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '767',
  //             parent_menu_id: '766',
  //             menu_caption: 'Issue Cross Reference',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/CrossRef/',
  //             url_path: 'XRefIssues',
  //             url_page: '/XRefIssueSearch.asp',
  //             url_target: '_self',
  //             display_order: '3',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //         ],
  //       },
  //       {
  //         menu_id: '1357',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus Recon',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'VirtusRecon',
  //         display_order: '12',
  //         parm: '',
  //         sub_menu: '1',
  //         global_level: '1',
  //         m_level3: [
  //           {
  //             menu_id: '1358',
  //             parent_menu_id: '1357',
  //             menu_caption: 'HFA DDA Recon Config',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/VirtusRecon/',
  //             url_path: 'HFA_DDA_Recon_Config',
  //             url_page: '/VPM_Fund.asp',
  //             url_target: '_self',
  //             display_order: '1',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '1359',
  //             parent_menu_id: '1357',
  //             menu_caption: 'HFA DDA Recon',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/VirtusRecon/',
  //             url_path: 'HFA_DDA_Recon',
  //             url_page: '/MatchedItems.asp',
  //             url_target: '_self',
  //             display_order: '2',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //         ],
  //       },
  //       {
  //         menu_id: '851',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Interest Rate History',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'InterestRateHistory',
  //         url_page: '/IndexRateList.asp',
  //         url_target: '_self',
  //         display_order: '13',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '1043',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Credit Agreements',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'CreditAgreements',
  //         url_page: '/CreditAgreementSearch.asp',
  //         url_target: '_self',
  //         display_order: '14',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '896',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Rate Update Exceptions',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'RateUpdateExceptions',
  //         url_page: '/RateUpdateExceptions.asp',
  //         url_target: '_self',
  //         display_order: '15',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '787',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Data Integrity',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'DataIntegrity',
  //         display_order: '16',
  //         parm: '',
  //         sub_menu: '1',
  //         global_level: '1',
  //         m_level3: [
  //           {
  //             menu_id: '823',
  //             parent_menu_id: '787',
  //             menu_caption: 'Facilities Missing Liens',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Facilities_Missing_Data/Missing_Liens',
  //             url_page: '/FacilitiesMissingLien.asp',
  //             url_target: '_self',
  //             display_order: '1',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '824',
  //             parent_menu_id: '787',
  //             menu_caption: 'Facilities Missing Ids',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Facilities_Missing_Data/Missing_Ids',
  //             url_page: '/FacilitiesMissingIds.asp',
  //             url_target: '_self',
  //             display_order: '2',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '825',
  //             parent_menu_id: '787',
  //             menu_caption: 'Facilities Missing Agent Bank',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Facilities_Missing_Data/Missing_Agent_Bank',
  //             url_page: '/FacilitiesMissingAgentBank.asp',
  //             url_target: '_self',
  //             display_order: '3',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '826',
  //             parent_menu_id: '787',
  //             menu_caption: 'Facilities - Active without Amort',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Facilities_Missing_Data/Active_with_Amort',
  //             url_page: '/FacilitiesActivewithAmort.asp',
  //             url_target: '_self',
  //             display_order: '4',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '822',
  //             parent_menu_id: '787',
  //             menu_caption: 'Original - Facilities Missing Data',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Facilities_Missing_Data/Original',
  //             url_page: '/MissingFacilityData.asp',
  //             url_target: '_self',
  //             display_order: '5',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '790',
  //             parent_menu_id: '787',
  //             menu_caption: 'Facilities with Unfunded Amounts and No Fees',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'FacilitieswithUnfundedAmounts',
  //             url_page: '/UnfundedFacilitiesWithoutFees.asp',
  //             url_target: '_self',
  //             display_order: '6',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '789',
  //             parent_menu_id: '787',
  //             menu_caption: 'Issuers without Country',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Issuerswithoutcountry',
  //             url_page: '/Issuerswithoutcountry.asp',
  //             url_target: '_self',
  //             display_order: '7',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '842',
  //             parent_menu_id: '787',
  //             menu_caption: 'Unrolled Contracts',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Unrolled_Contracts',
  //             url_page: '/Unrolled_Contracts.asp',
  //             url_target: '_self',
  //             display_order: '8',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //           {
  //             menu_id: '845',
  //             parent_menu_id: '787',
  //             menu_caption: 'Positions With Negative Amount',
  //             menu_type: 'M',
  //             url_full_path: '/Apps/GlobalInfo/DataIntegrity/',
  //             url_path: 'Positions_with_Negative_Amt',
  //             url_page: '/PositionswithNegativeAmt.asp',
  //             url_target: '_self',
  //             display_order: '9',
  //             parm: '',
  //             sub_menu: '0',
  //             global_level: '1',
  //           },
  //         ],
  //       },
  //       {
  //         menu_id: '1143',
  //         parent_menu_id: '218',
  //         menu_caption: 'Virtus - Dynamic Report Data Dictionary',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/GlobalInfo/',
  //         url_path: 'DynamicReportDataDictionary',
  //         url_page: '/DR_Data_Dictionary.asp',
  //         url_target: '_self',
  //         display_order: '17',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //     ],
  //   },
  //   {
  //     menu_id: '121',
  //     parent_menu_id: '0',
  //     menu_caption: 'Reports',
  //     menu_type: 'M',
  //     url_full_path: '/Apps/',
  //     url_path: 'Reports',
  //     display_order: '5',
  //     parm: '',
  //     sub_menu: '1',
  //     global_level: '0',
  //     m_level2: [
  //       {
  //         menu_id: '940',
  //         parent_menu_id: '121',
  //         menu_caption: 'Run Report',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'RunReport',
  //         url_page: '/ExecutingReports.asp',
  //         url_target: '_self',
  //         display_order: '1',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '748',
  //         parent_menu_id: '121',
  //         menu_caption: 'Run Report Groups',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'RunReportGroups',
  //         url_page: '/ReportList.asp',
  //         url_target: '_self',
  //         display_order: '2',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '375',
  //         parent_menu_id: '121',
  //         menu_caption: 'Accrued Income Report',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'AccruedInterest',
  //         url_page: '/AccruedInterestReport.asp',
  //         url_target: '_self',
  //         display_order: '3',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '947',
  //         parent_menu_id: '121',
  //         menu_caption: 'Base Rate Validation',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'BaseRateValidation',
  //         url_page: '/BaseRateValidation.asp',
  //         url_target: '_self',
  //         display_order: '6',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '941',
  //         parent_menu_id: '121',
  //         menu_caption: 'Global and Deal Contract Comparison',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'GlobalDealLoanComparison',
  //         url_page: '/GlobalDealLoanComparison.asp',
  //         url_target: '_self',
  //         display_order: '7',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '957',
  //         parent_menu_id: '121',
  //         menu_caption: 'Agent Notices',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'AgentNotices',
  //         url_page: '/AgentNotices.asp',
  //         url_target: '_self',
  //         display_order: '8',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '946',
  //         parent_menu_id: '121',
  //         menu_caption: 'Amortization Validation',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'AmortizationValidation',
  //         url_page: '/AmortizationValidation.asp',
  //         url_target: '_self',
  //         display_order: '9',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '757',
  //         parent_menu_id: '121',
  //         menu_caption: 'Expected Transactions Report',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'ExpectedTransactions',
  //         url_page: '/ExpectedTransactions.asp',
  //         url_target: '_self',
  //         display_order: '10',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '1069',
  //         parent_menu_id: '121',
  //         menu_caption: 'Expected Global Facility Transactions Report',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'ExpectedFacGlobalTrans',
  //         url_page: '/ExpectedFacGlobalTrans.asp',
  //         url_target: '_self',
  //         display_order: '11',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //       {
  //         menu_id: '1437',
  //         parent_menu_id: '121',
  //         menu_caption: 'Virtus - Billing Reports',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'BillingReports',
  //         display_order: '15',
  //         parm: '',
  //         sub_menu: '1',
  //         global_level: '1',
  //         m_level3: {
  //           menu_id: '1438',
  //           parent_menu_id: '1437',
  //           menu_caption: 'Monthly Billing Report - PacLife',
  //           menu_type: 'M',
  //           url_full_path: '/Apps/Reports/BillingReports/',
  //           url_path: 'Paclife',
  //           url_page: '/BillingReportPaclife.asp',
  //           url_target: '_self',
  //           display_order: '1',
  //           parm: '',
  //           sub_menu: '0',
  //           global_level: '1',
  //         },
  //       },
  //       {
  //         menu_id: '1274',
  //         parent_menu_id: '121',
  //         menu_caption: 'Virtus - Pending Delayed Comp Report',
  //         menu_type: 'M',
  //         url_full_path: '/Apps/Reports/',
  //         url_path: 'PendingDelayedCompReport',
  //         url_page: '/PendingDelayedCompReport.asp',
  //         url_target: '_self',
  //         display_order: '28',
  //         parm: '',
  //         sub_menu: '0',
  //         global_level: '1',
  //       },
  //     ],
  //   },
];

export default apiRoutes;
