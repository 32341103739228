import LoadingIcon, { LoadingIconSizes, LoadingIconType } from '@virtus/components/LoadingIcon/LoadingIcon';
import 'components/ActivityIndicator/ActivityIndicator.css';
import React from 'react';

const style = {
  bottom: 0,
  height: '100px',
  left: 0,
  margin: 'auto',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100px',
} as React.CSSProperties;

const PageLoadingIndicator = () => {
  return (
    <div style={style}>
      <LoadingIcon type={LoadingIconType.Virtus} size={LoadingIconSizes.large} show={true} />
      <br />
    </div>
  );
};

export default PageLoadingIndicator;
