import { navigate } from '@reach/router';
import { fetchData } from 'data/DataConnector';
import { setEntityId } from 'helpers/AuthenticationHelper';
import React from 'react';
import styled from 'styled-components';
import { getTrimmedValue } from 'util/helpers/helperFunctions';
import { MethodType } from './DropDown/MethodType';
import SearchableDropdown from './DropDown/SearchableDropdown';

interface IProps {
  entityId: number | undefined;
  userId: string;
}

interface IState {
  isLoading: boolean;
}

let deals: any[] = [];

class DealSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  public async componentDidMount() {
    deals = await this.getDeals(this.props.userId);
  }

  public render() {
    return (
      <SearchableDropdown style={{ width: '25vw' }} value={this.getValue()} size={'md'} onChange={this.onDealChange}>
        {(setToActive: any, search: string) => {
          const filteredDeals = deals.filter(
            (row: any) => !search || row['Deal Nickname'].toLowerCase().includes(search.toLowerCase()),
          );
          return (
            <>
              {filteredDeals.map((deal: any) => {
                return (
                  <Item
                    key={deal.ID}
                    isSelected={getTrimmedValue(this.props.entityId) === getTrimmedValue(deal.ID)}
                    onClick={(e: any) => setToActive(e, deal)}
                  >
                    <ItemText>{deal['Deal Nickname']}</ItemText>
                  </Item>
                );
              })}
            </>
          );
        }}
      </SearchableDropdown>
    );
  }

  private getValue() {
    if (this.state.isLoading) {
      return 'Loading...';
    } else if (
      deals.filter((row: any) => getTrimmedValue(row.ID) === getTrimmedValue(this.props.entityId)).length > 0
    ) {
      return deals.filter((row: any) => getTrimmedValue(row.ID) === getTrimmedValue(this.props.entityId))[0][
        'Deal Nickname'
      ];
    } else {
      return 'Select a Deal';
    }
  }

  private async onDealChange(event: any, data: any) {
    await setEntityId(data.ID);
    navigate('/DealSetup/DealInfo/Main/Entity/entityId/' + data.ID);
  }

  private async getDeals(userId: string) {
    // if (deals.length > 0) {
    //   return deals;
    // }
    // await this.setState({
    //   isLoading: true,
    // });
    const response = await fetchData('Sql', 'EntityList', MethodType.Post, {
      userId,
    });
    // await this.setState({
    //   isLoading: false,
    // });
    return response.dataObject.data;
  }
}

const Item: any = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-color: ${(props: any) => (props.isSelected ? 'rgba(255, 255, 255, 0.14)' : 'transparent')};
  :hover {
    background-color: rgba(255, 255, 255, 0.14);
  }
`;

const ItemText: any = styled.p`
  padding: 0px;
  margin: 0px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
`;

export default DealSelector;
