import * as dealActionTypes from 'actions/dealActionTypes';

const defaultState = {
  dealsList: undefined,
};

export const dealReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case dealActionTypes.SET_DEAL_LIST:
      return {
        ...state,
        dealsList: action.data,
      };
    default:
      return state;
  }
};
