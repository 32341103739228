import { SET_SIGNALR_DATA } from 'actions/signalRActions';

interface IState {
  data: any;
}

const defaultState: IState = {
  data: {},
};

export const signalRReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case SET_SIGNALR_DATA:
      return {
        data: {
          ...state.data,
          [action.data.key]: {
            data: action.data.data,
            status: action.data.status,
          },
        },
      };
    default:
      return state;
  }
};
