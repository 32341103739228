import React from 'react';

interface IProps {
  width?: number | undefined;
  height?: number | undefined;
}

const GenesisIcon = ({ width = 20, height = 20 }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
      <defs>
        <filter id="a" width="112.1%" height="131.3%" x="-6%" y="-15.7%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" filter="url(#a)">
        <path d="M0 0H20V20H0z" />
        <path
          fill="#5F56C3"
          d="M6.642 3.874l2.686-2.687c.371-.37.973-.37 1.344 0l2.686 2.687c.371.37.371.972 0 1.343l-2.686 2.687c-.371.37-.973.37-1.344 0L6.642 5.217c-.371-.37-.371-.972 0-1.343z"
        />
        <path
          fill="#A49FDB"
          d="M6.642 14.783l2.686-2.687c.371-.37.973-.37 1.344 0l2.686 2.687c.371.37.371.972 0 1.343l-2.686 2.687c-.371.37-.973.37-1.344 0l-2.686-2.687c-.371-.37-.371-.972 0-1.343z"
        />
        <path
          fill="#7770CA"
          d="M1.187 9.328l2.687-2.686c.37-.371.972-.371 1.343 0l2.687 2.686c.37.371.37.973 0 1.344l-2.687 2.686c-.37.371-.972.371-1.343 0l-2.687-2.686c-.37-.371-.37-.973 0-1.344z"
        />
        <path
          fill="#C0BCE6"
          d="M12.096 9.328l2.687-2.686c.37-.371.972-.371 1.343 0l2.687 2.686c.37.371.37.973 0 1.344l-2.687 2.686c-.37.371-.972.371-1.343 0l-2.687-2.686c-.37-.371-.37-.973 0-1.344z"
        />
      </g>
    </svg>
  );
};

export default GenesisIcon;
