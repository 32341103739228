import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ReportsIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M10 2h2.998C13.544 2 14 2.45 14 3.007v10.986C14 14.55 13.551 15 12.998 15H3.002C2.456 15 2 14.55 2 13.993V3.007C2 2.45 2.449 2 3.002 2H6c0-1.105.895-2 2-2s2 .895 2 2zM6 8.047H4v4.967h2V8.047zm6 .994h-2v3.973h2V9.04zM9 5.068H7v7.946h2V5.068zM8 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
      />
    </g>
  </SvgIcon>
);
