export const FacilityGet = 'FacilityGet';
export const VrtsDeletedTransactionNotificationPut = 'VrtsDeletedTransactionNotificationPut';
export const FacilityGlobalTransactionList = 'FacilityGlobalTransactionList';
export const FileStorageList = 'FileStorageList';
export const FacilityGlobalTransactionGet = 'FacilityGlobalTransactionGet';
export const VrtsCreditAgreementCurrencyCodesList = 'VrtsCreditAgreementCurrencyCodesList';
export const VrtsCreditAgreementCurrencyPut = 'VrtsCreditAgreementCurrencyPut';
export const VrtsCreditAgreementCreateAmendment = 'VrtsCreditAgreementCreateAmendment';
export const VrtsCreditAgreementPut = 'VrtsCreditAgreementPut';
export const FacilityGlobalTransactionSpreadList = 'FacilityGlobalTransactionSpreadList';
export const FacilityGlobalTransSpreadLoanList = 'FacilityGlobalTransSpreadLoanList';
export const FacilityGlobalTransactionLoanList = 'FacilityGlobalTransactionLoanList';
export const FacilityGlobalTransOpenTradeList = 'FacilityGlobalTransOpenTradeList';
export const FacilityGlobalTransDealList = 'FacilityGlobalTransDealList';
export const VrtsRatingsCountryXrefList = 'VrtsRatingsCountryXrefList';
export const VrtsRatingsCountryXrefGet = 'VrtsRatingsCountryXrefGet';
export const VrtsCreditAgreementFacilityPut = 'VrtsCreditAgreementFacilityPut';
export const VrtsCreditAgreementFacilityListAvailable = 'VrtsCreditAgreementFacilityListAvailable';
export const LoanPut = 'LoanPut';
export const LoanGet = 'LoanGet';
export const EntityList = 'EntityList';
export const DealPaymentScheduleList = 'DealPaymentScheduleList';
export const EntityGet = 'EntityGet';
export const VrtsFacilitiesMissingLiens = 'VrtsFacilitiesMissingLiens';
export const CompanyIssuerRatingList = 'CompanyIssuerRatingList';
export const IssuerGet = 'IssuerGet';
export const VrtsFacilitiesMissingIds = 'VrtsFacilitiesMissingIds';
export const VrtsFacilitiesMissingAgentBank = 'VrtsFacilitiesMissingAgentBank';
export const VrtsFacilitiesActivewithAmort = 'VrtsFacilitiesActivewithAmort';
export const VrtsMissingFacilityData = 'VrtsMissingFacilityData';
export const VrtsUnfundedFacilitiesWithoutFees = 'VrtsUnfundedFacilitiesWithoutFees';
export const VrtsIssuersWithoutcountry = 'VrtsIssuersWithoutcountry';
export const VrtsPositionsWithNegativeAmt = 'VrtsPositionsWithNegativeAmt';
export const VrtsUnrolledContractsList = 'VrtsUnrolledContractsList';
export const VrtsCreditAgreementDataAuditSearch = 'VrtsCreditAgreementDataAuditSearch';
export const RsFacilityGet = 'RsFacilityGet';
export const CdosaTabXmlList = 'CdosaTabXmlList';
export const CdosaUserPageAccess = 'CdosaUserPageAccess';
export const RsIssueGet = 'RsIssueGet';
export const IssueGet = 'IssueGet';
export const RsIssuePurchaseList = 'RsIssuePurchaseList';
export const RdScenarioList = 'RdScenarioList';
export const AccruedInterestReport = 'AccruedInterestReport';
