import config from 'config/index';
import createNetworkInterface, { csClient } from './reduxQueryNetworkInterface';

const onUnauthorized = () => {
  console.info('un-authorized');
};

let signalRDetail: any;
let useSignalR: boolean;
let networkInterface: any;

export interface ISignalRProps {
  isUseSignalR: boolean;
}

export class SignalRConnector {
  constructor({ isUseSignalR }: ISignalRProps) {
    this.connectToSignalR = this.connectToSignalR.bind(this);
    if (signalRDetail === undefined) {
      useSignalR = isUseSignalR;
    }
    networkInterface = createNetworkInterface({ ...config, onUnauthorized });
  }

  public async connectToSignalR() {
    await csClient.connectToSignalR();
    signalRDetail = csClient.getSignalRDetail();
    return signalRDetail.SignalrClientId;
  }

  public updateUseSignalR(useSignalRValue: boolean) {
    useSignalR = useSignalRValue;
  }

  public async disconnectSignalR() {
    await csClient.disconnectSignalR();
    signalRDetail = undefined;
  }
}

export default SignalRConnector;
export { signalRDetail, useSignalR, networkInterface };
