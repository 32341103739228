import TextboxControlObject from 'components/TextboxControl/TextboxControlObject';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import DevXCalendar, { IProps } from './DevXCalendar';

export interface IProp extends IProps {
  isReadonly: boolean;
}

export interface IState {
  showCalendar: boolean;
}

export function DevXCalendarObject(props: IProp) {
  return (
    <div>
      {' '}
      {props.isReadonly !== true ? (
        <DevXCalendar
          isDisabled={props.isDisabled}
          onBlur={props.onBlur}
          value={props.value ? props.value : undefined}
          onChange={props.onChange}
          isRequired={props.isRequired}
          isValid={props.isValid}
          validationMessage={props.validationMessage}
          name={props.name}
          datePickerPosition={props.datePickerPosition}
          checkBusinessDay={props.checkBusinessDay}
          nbdAdjustment={props.nbdAdjustment}
          jurisdictionCodesString={props.jurisdictionCodesString}
          style={props.style}
        />
      ) : (
        <TextboxControlObject name="datePicker" value={getFormattedDate(props.value)} isReadonly={true} />
      )}
    </div>
  );
}
export function getFormattedDate(date: Date | undefined) {
  if (moment(date).isValid() && date !== undefined) {
    return new Date(date).toLocaleDateString();
  }
  return '';
}
const mapStateToProps = (stateFromStore: any) => {
  return {
    format: stateFromStore.globalRdcr.dateFormat,
  };
};

export default connect(mapStateToProps)(DevXCalendarObject);
