import * as globalActions from 'actions/globalActions';
import cloneDeep from 'lodash/cloneDeep';
import mockApiRoutes from 'mockData/apiRoutes';
import moment from 'moment';
import { ConvertDouble, getLocaleDateString } from 'util/helpers/helperFunctions';
import { configureAPIRoutes, IRoute, parseTabularRoutes } from './routeFormatter';

enum Menus {
  Global,
  Deal,
}

interface IMenu {
  firstChild: number;
  fullPath: string;
  parentId: number;
  type: string;
  urlPage?: string;
  caption?: string;
}

interface IUserGroup {
  groupName: string;
  groupId: number;
}

interface IState {
  errorMessage: any;
  allowEdit: boolean;
  allowDelete: boolean;
  allowAdd: boolean;
  heightToRemove: number;
  currentId: number;
  entityId?: number;
  companyId: number;
  dateFormat: string;
  menu: any;
  sideMenu: any;
  route: IRoute[];
  username: string;
  userFullName: string;
  placeholder: string;
  saAtCode: string;
  defaultPathMap: any;
  menuCommand: string;
  dealMenu: any;
  globalMenu: any;
  completeMenu: any;
  currentMenu: Menus;
  useReadonlyHeader: boolean;
  userGroups: IUserGroup[];
}

const defaultState: IState = {
  allowAdd: true,
  allowDelete: true,
  allowEdit: true,
  companyId: 0,
  completeMenu: [],
  currentId: 0,
  currentMenu: Menus.Global,
  dateFormat: 'M/D/YYYY',
  dealMenu: [],
  defaultPathMap: {},
  errorMessage: '',
  globalMenu: [],
  heightToRemove: 0,
  menu: mockApiRoutes,
  menuCommand: '',
  placeholder: '',
  route: [],
  saAtCode: '',
  sideMenu: mockApiRoutes,
  useReadonlyHeader: true,
  userFullName: '',
  userGroups: [],
  username: '',
};

export const globalReducer = (state: IState = defaultState, action: any) => {
  switch (action.type) {
    case globalActions.SET_USER:
      return {
        ...state,
        username: action.data,
      };
    case globalActions.SET_USER_FULLNAME:
      return {
        ...state,
        userFullName: action.data,
      };
    case globalActions.INIT_MENU:
      const completeMenu = parseTabularRoutes(action.data[0]);
      const defaultPathMap1 = setDefaultPath(action.data[0]);
      let globalMenu = state.globalMenu;
      let dealMenu = state.dealMenu;
      if (action.data[1]) {
        const dealMenuObject = action.data[1];

        let globalMenuObject = cloneDeep(action.data[1]);

        globalMenuObject = globalMenuObject.m_level1.filter((menu: any) => menu.global_level === '1');
        filterSubMenu(globalMenuObject);

        globalMenu = globalMenuObject ? configureAPIRoutes(globalMenuObject, true) : globalMenu;

        dealMenu = configureAPIRoutes(
          dealMenuObject.m_level1 instanceof Array ? dealMenuObject.m_level1 : [dealMenuObject.m_level1],
          true,
        );
      }
      return {
        ...state,
        completeMenu,
        currentMenu: Menus.Global,
        dealMenu,
        defaultPathMap: defaultPathMap1,
        globalMenu,
        route: globalMenu,
        sideMenu: globalMenu,
      };
    case globalActions.SET_MENU:
      const entityId = getEntityIdFromPath(action.data) || state.entityId;
      const update = Boolean(entityId) !== (state.currentMenu === Menus.Deal);
      const route = update ? (entityId ? cloneDeep(state.dealMenu) : cloneDeep(state.globalMenu)) : state.route;
      const sideMenu = update ? (entityId ? cloneDeep(state.dealMenu) : cloneDeep(state.globalMenu)) : state.sideMenu;
      return {
        ...state,
        currentMenu: entityId ? Menus.Deal : Menus.Global,
        entityId,
        route,
        sideMenu,
      };
    case globalActions.APPEND_MENU:
      const newState = appendMenu(state, action);
      const newMenu = cloneDeep(newState.menu);
      return {
        ...state,
        menu: newMenu,
      };
    case globalActions.APPEND_MENU_TO_PARENT:
      const newState1 = appendMenuToParent(state, action);
      const newMenu1 = cloneDeep(newState1.menu);
      return {
        ...state,
        menu: newMenu1,
      };
    case globalActions.SET_ENTITY_ID:
      return {
        ...state,
        entityId: action.data,
      };
    case globalActions.SET_HEIGHT_TO_REMOVE:
      return {
        ...state,
        heightToRemove: action.data,
      };
    case globalActions.GET_DATE_FORMAT:
      return {
        ...state,
        dateFormat: getDateFormat(),
      };
    case globalActions.GET_DATE_PLACEHOLDER:
      return {
        ...state,
        placeholder: getPlaceholder(state),
      };
    case globalActions.SET_USER_PERMISSIONS:
      const newUserPermissionState = getUserPermissions(state, action.data);
      const updatedState = cloneDeep(newUserPermissionState);
      return { ...updatedState };

    case globalActions.REMOVE_SUBROUTES:
      return {
        ...state,
        route: cloneDeep(state.sideMenu),
      };
    case globalActions.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.data,
      };
    case globalActions.SET_DEFAULT_PATH:
      const defaultPathMap = setDefaultPath(action.data.data);
      return {
        ...state,
        defaultPathMap,
      };
    case globalActions.APPEND_TAB_MENU:
      state.route = cloneDeep(state.sideMenu);
      getAppendMenu(ConvertDouble(action.data), state, true, cloneDeep(state.completeMenu));
      return {
        ...state,
      };
    case globalActions.SET_READONLY_HEADER:
      return {
        ...state,
        useReadonlyHeader: action.data,
      };
    case globalActions.SET_USER_GROUPS:
      const userGroups: IUserGroup[] = action.data
        .filter((row: any) => row.in_group)
        .map((row: any) => ({ groupName: row.group_name, groupId: row.group_id }));
      return {
        ...state,
        userGroups,
      };
    default:
      return state;
  }
};

const filterMenuLevel8 = (currentMenu: any) => {
  if (currentMenu.m_level8) {
    if (currentMenu.m_level8 instanceof Array) {
      currentMenu.m_level8 = currentMenu.m_level8.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
    }
  }
};

const filterMenuLevel7 = (currentMenu: any) => {
  if (currentMenu.m_level7) {
    if (currentMenu.m_level7 instanceof Array) {
      currentMenu.m_level7 = currentMenu.m_level7.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level7) {
        filterMenuLevel8(subMenu);
      }
    } else if (currentMenu.m_level7.m_level8) {
      filterMenuLevel8(currentMenu.m_level7.m_level8);
    }
  }
};

const filterMenuLevel6 = (currentMenu: any) => {
  if (currentMenu.m_level6) {
    if (currentMenu.m_level6 instanceof Array) {
      currentMenu.m_level6 = currentMenu.m_level6.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level6) {
        filterMenuLevel7(subMenu);
      }
    } else if (currentMenu.m_level6.m_level7) {
      filterMenuLevel7(currentMenu.m_level6.m_level7);
    }
  }
};

const filterMenuLevel5 = (currentMenu: any) => {
  if (currentMenu.m_level5) {
    if (currentMenu.m_level5 instanceof Array) {
      currentMenu.m_level5 = currentMenu.m_level5.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level5) {
        filterMenuLevel6(subMenu);
      }
    } else if (currentMenu.m_level5.m_level6) {
      filterMenuLevel6(currentMenu.m_level5.m_level6);
    }
  }
};

const filterMenuLevel4 = (currentMenu: any) => {
  if (currentMenu.m_level4) {
    if (currentMenu.m_level4 instanceof Array) {
      currentMenu.m_level4 = currentMenu.m_level4.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level4) {
        filterMenuLevel5(subMenu);
      }
    } else if (currentMenu.m_level4.m_level5) {
      filterMenuLevel5(currentMenu.m_level4.m_level5);
    }
  }
};

const filterMenuLevel3 = (currentMenu: any) => {
  if (currentMenu.m_level3) {
    if (currentMenu.m_level3 instanceof Array) {
      currentMenu.m_level3 = currentMenu.m_level3.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level3) {
        filterMenuLevel4(subMenu);
      }
    } else if (currentMenu.m_level3.m_level4) {
      filterMenuLevel4(currentMenu.m_level3.m_level4);
    }
  }
};

const filterMenuLevel2 = (currentMenu: any) => {
  if (currentMenu.m_level2) {
    if (currentMenu.m_level2 instanceof Array) {
      currentMenu.m_level2 = currentMenu.m_level2.filter(
        (menu: any) => menu.global_level === '1' || menu.menu_type !== 'M',
      );
      for (let subMenu of currentMenu.m_level2) {
        filterMenuLevel3(subMenu);
      }
    } else if (currentMenu.m_level2.m_level3) {
      filterMenuLevel3(currentMenu.m_level2.m_level3);
    }
  }
};

const filterSubMenu = (currentMenu: any) => {
  for (let subMenu of currentMenu) {
    filterMenuLevel2(subMenu);
  }
};

const getAppendMenu = (menuId: number, state: IState, firstTime: boolean, completeMenu: any) => {
  if (menuId in state.defaultPathMap && state.defaultPathMap[menuId].type !== 'M') {
    const toAppend: any[] = [];
    const parentMenuId = state.defaultPathMap[menuId].parentId;

    const parentMenu: IMenu = state.defaultPathMap[parentMenuId];
    const parentRoute = find(completeMenu, parentMenuId);

    if (firstTime) {
      for (const subroute of parentRoute.subroutes) {
        if (subroute.menuId === menuId) {
          subroute.subroutes = undefined;
        }
      }
    }
    if (parentMenu.type === 'G') {
      if (state.defaultPathMap[menuId].type === 'G') {
        parentRoute.subroutes = parentRoute.subroutes.filter((subroute: any) => subroute.menuId === menuId);
      }
      toAppend.push(parentRoute);
      if (parentMenu.fullPath.endsWith('Properties')) {
        const transPath = parentMenu.fullPath.replace(new RegExp('Properties$'), 'Transactions');
        const transId = Object.keys(state.defaultPathMap).filter(
          menu => state.defaultPathMap[menu].fullPath === transPath,
        )[0];
        if (transId) {
          toAppend.push({ ...find(completeMenu, ConvertDouble(transId)) });
        }
      } else if (parentMenu.fullPath.endsWith('Transactions')) {
        const propsPath = parentMenu.fullPath.replace(new RegExp('Transactions$'), 'Properties');
        const propsId = Object.keys(state.defaultPathMap).filter(
          menu => state.defaultPathMap[menu].fullPath === propsPath,
        )[0];
        toAppend.splice(0, 0, { ...find(completeMenu, ConvertDouble(propsId)) });
      }
    } else if (parentMenu.type === 'M') {
      const siblings = Object.keys(state.defaultPathMap).filter(
        menu => state.defaultPathMap[menu].parentId === parentMenuId,
      );
      for (const sibling of siblings) {
        toAppend.push({ ...find(completeMenu, ConvertDouble(sibling)) });
      }
    } else if (parentMenu.type === 'T') {
      toAppend.splice(0, toAppend.length);
      const siblings = Object.keys(state.defaultPathMap).filter(
        menu => state.defaultPathMap[menu].parentId === state.defaultPathMap[parentMenuId].parentId,
      );
      for (const sibling of siblings) {
        toAppend.push({ ...find(completeMenu, ConvertDouble(sibling)) });
      }
    }
    append(toAppend, state, completeMenu);
  }
};

const append = (menus: any[], state: IState, completeMenu: any) => {
  menus.sort((a: IRoute, b: IRoute) => ConvertDouble(a.displayOrder) - ConvertDouble(b.displayOrder));
  const parentmenuId = menus[0].parentMenuId;
  const parentMenu = find(state.route, parentmenuId);
  if (parentMenu) {
    // Menu ID 1406: Global Information >> Client Information
    parentMenu.subroutes = ConvertDouble(menus[0].menuId) === 1406 ? [...menus, ...parentMenu.subroutes] : menus;
  } else if (find(state.completeMenu, parentmenuId)) {
    getAppendMenu(menus[0].menuId, state, false, completeMenu);
    const pMenu = find(state.route, parentmenuId);
    pMenu.subroutes = menus;
  }
};

export const find = (menus: any, menuId: number): any => {
  if (!menus) {
    return;
  }
  for (const menu of menus) {
    if (ConvertDouble(menu.menuId) === menuId) {
      return menu;
    }
    const foundMenu: any = find(menu.subroutes, menuId);
    if (foundMenu) {
      return foundMenu;
    }
  }
};

const setDefaultPath = (menuData: any[]) => {
  const defaultPath: any = {};
  menuData.forEach(element => {
    let fullPath = element.url_full_path.replace('/Apps', '').replace(new RegExp('_', 'g'), '');
    fullPath = fullPath.endsWith('/') ? fullPath.slice(0, -1) : fullPath;
    let urlPath = element.url_path.replace(new RegExp('_', 'g'), '');
    urlPath = element.menu_id < 0 ? urlPath : urlPath.replace(new RegExp('/', 'g'), '_');
    urlPath = urlPath.startsWith('/') ? urlPath : `/${urlPath}`;
    let urlPage: string = element.url_page
      ? element.url_page.replace(new RegExp('.asp', 'ig'), '').replace(new RegExp('_', 'g'), '')
      : '';
    urlPage = urlPage.includes('?') ? urlPage.substring(0, urlPage.indexOf('?')) : urlPage;
    defaultPath[element.menu_id] = {
      caption: element.menu_caption,
      firstChild: 0,
      fullPath: fullPath + urlPath + urlPage,
      originalPath: element.url_full_path.replace('/Apps', '') + element.url_path,
      parentId: element.parent_menu_id,
      type: element.menu_type,
      urlPage,
    };
    const parentMenuId = element.parent_menu_id;
    if (
      defaultPath[parentMenuId] &&
      !defaultPath[parentMenuId].urlPage &&
      (defaultPath[parentMenuId].firstChild === 0 ||
        element.is_default ||
        (defaultPath[defaultPath[parentMenuId].firstChild].type !== 'M' && element.menu_type === 'M'))
    ) {
      defaultPath[parentMenuId].firstChild = element.menu_id;
    }
  });
  updateDefaultPath(defaultPath);
  return defaultPath;
};

const updateDefaultPath = (defaultPath: any) => {
  defaultPath[0] = {
    firstChild: 0,
    fullPath: '/DealList',
    type: 'M',
  };
  defaultPath[220] = {
    ...defaultPath[220],
    fullPath: '/GlobalInfo/LoanFacilities/FacilitySearch',
    urlPage: '/FacilitySearch',
  };
};

function getUserPermissions(state: any, data: any) {
  if (data && data.length > 0) {
    state = {
      ...state,
      allowAdd: data[0].A_code === 1 ? true : false,
      allowDelete: data[0].D_code === 1 ? true : false,
      allowEdit: data[0].E_code === 1 ? true : false,
      saAtCode: data[0].at_code,
    };
  }
  return state;
}

function getPlaceholder(state: any) {
  let placeholder = state.dateFormat;
  if (state.dateFormat !== undefined) {
    if ((state.dateFormat.match(new RegExp('D', 'g')) || []).length === 1) {
      placeholder = placeholder.replace('D', 'DD');
    }
    if ((state.dateFormat.match(new RegExp('M', 'g')) || []).length === 1) {
      placeholder = placeholder.replace('M', 'MM');
    }
  }
  return placeholder;
}

function appendMenuToParent(state: any, action: any): any {
  const data = configureAPIRoutes(action.data.m_level1.m_level2);
  findParentInRoutes(state.route, action.parentId, data, action.overrideExisting);
  return state;
}

function appendMenu(state: any, action: any): any {
  const data = configureAPIRoutes([action.data.m_level1]);
  const found = findParentInRoutes(state.route, action.data.m_level1.parent_menu_id, data, action.overrideExisting);
  if (!found && action.data.m_level1.menu_type !== 'M') {
    const fullPath = data[0].fullPath
      ? data[0].fullPath.startsWith('/')
        ? data[0].fullPath.substring(1)
        : data[0].fullPath
      : '';
    const pathArray = fullPath.split('/');
    data[0].path = data[0].path.startsWith('/') ? data[0].path.substring(1) : data[0].path;
    const filtered = state.route.filter((route: IRoute) => route.path === pathArray[0]);
    createParent(filtered[0], data, pathArray, 1);
  }
  return state;
}

function createParent(route: IRoute, data: IRoute[], pathArray: string[], index: number) {
  if (data[0].path === pathArray[index]) {
    if (!route.subroutes) {
      route.subroutes = data;
    } else {
      route.subroutes.push(data[0]);
    }
    return;
  }
  if (route.subroutes && route.subroutes.length > 0) {
    const filtered = route.subroutes.filter((subroute: IRoute) => subroute.path === pathArray[index]);
    if (filtered.length > 0) {
      createParent(filtered[0], data, pathArray, index + 1);
    } else {
      const newRoute: IRoute = {
        menuId: Math.round(Math.random() * 100 + 10000).toString(),
        name: pathArray[index],
        parentMenuId: route.menuId,
        path: pathArray[index],
      };
      route.subroutes.push(newRoute);
      createParent(newRoute, data, pathArray, index + 1);
    }
  } else {
    const newRoute: IRoute[] = [
      {
        menuId: Math.random().toString(),
        name: pathArray[index],
        parentMenuId: route.menuId,
        path: pathArray[index],
      },
    ];
    route.subroutes = newRoute;
    createParent(route.subroutes[0], data, pathArray, index + 1);
  }
}

function findParentInRoutes(routes: IRoute[], parentId: string, data: IRoute[], overrideExisting?: boolean): boolean {
  for (const index in routes) {
    if (findParent(routes[index], parentId, data, overrideExisting)) {
      return true;
    }
  }
  return false;
}

function findParent(route: IRoute, parentId: string, data: IRoute[], overrideExisting?: boolean): boolean {
  if (route.menuId === parentId) {
    if (route.subroutes === undefined || overrideExisting) {
      route.subroutes = data;
    }
    // Special case to allow properties and transactions to be added as siblings
    else if ((parentId === '480' || parentId === '220') && route.subroutes.length === 1) {
      if (
        route.subroutes[0].name.endsWith('Properties') &&
        data[0].name === route.subroutes[0].name.replace('Properties', 'Transactions')
      ) {
        route.subroutes = [...route.subroutes, data[0]];
      } else if (
        route.subroutes[0].name.endsWith('Transactions') &&
        data[0].name === route.subroutes[0].name.replace('Transactions', 'Properties')
      ) {
        route.subroutes = [data[0], ...route.subroutes];
      }
    } else {
      return addInChildren(route.subroutes, data);
    }
    return true;
  }
  if (route.subroutes !== undefined) {
    for (const index in route.subroutes) {
      if (findParent(route.subroutes[ConvertDouble(index)], parentId, data, overrideExisting)) {
        return true;
      }
    }
  }
  return false;
}

function addInChildren(subRoutes: IRoute[], data: IRoute[]) {
  const menuId = data[0].menuId;
  for (const index in subRoutes) {
    if (subRoutes[index].menuId === menuId) {
      subRoutes[index].subroutes = data[0].subroutes;
      return true;
    }
  }
  return false;
}

function getDateFormat() {
  const dateYear = 2019;
  const dateMonth = 11 - 1; // month is zero based, November will be 11-1
  const dateDay = 23;
  const languages = navigator.languages;
  let finalFormat = 'M/D/YYYY';
  const testDate = new Date(dateYear, dateMonth, dateDay).toLocaleDateString();
  const date = new Date(moment(testDate, finalFormat).format()).toLocaleDateString();
  if (date === testDate) {
    finalFormat = 'M/D/YYYY';
  } else {
    if (languages !== undefined) {
      for (const language of languages) {
        const format = getLocaleDateString(language);
        const dt = new Date(moment(testDate, format).format()).toLocaleDateString();
        if (dt === testDate) {
          finalFormat = format;
          break;
        }
      }
    }
  }
  return finalFormat;
}

const getEntityIdFromPath = (path: string) => {
  let entityId = 0;
  const index = path.indexOf('entityId/');
  if (index !== -1) {
    const array = path.substring(index).split('/');
    if (array.length > 1) {
      entityId = isNaN(Number(array[1])) ? 0 : Number(array[1]);
    }
  }
  return entityId;
};
