import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const GlobalInfoIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H16V16H0z" />
      <path
        fill="#FFF"
        d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm-.8 14.344C4.04 13.952 1.6 11.264 1.6 8c0-.496.064-.968.168-1.432L5.6 10.4v.8c0 .88.72 1.6 1.6 1.6v1.544zm5.52-2.032c-.208-.648-.8-1.112-1.52-1.112h-.8V8.8c0-.44-.36-.8-.8-.8H4.8V6.4h1.6c.44 0 .8-.36.8-.8V4h1.6c.88 0 1.6-.72 1.6-1.6v-.328c2.344.952 4 3.248 4 5.928 0 1.664-.64 3.176-1.68 4.312z"
      />
    </g>
  </SvgIcon>
);
