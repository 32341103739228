import 'components/hoc/PopupModal/PopupModal.css';
import * as ColorPalette from 'components/Themes/theme';
import { Popup } from 'devextreme-react';

interface IProps {
  children: any;
  isModalOpen: boolean;
  modalHeader: any;
  initialized?: boolean;
  extraProps?: any;
  onResizeEnd?: any;
}

const styles = {
  dialogPaper: {
    background: ColorPalette.colors.bgDark,
    containment: 'window',
    height: 'max-content',
    maxHeight: '90vh',
    maxWidth: '90vw',
    width: 'max-content',
  },
};

const DraggableModal = (props: IProps) => {
  const extraProps = { ...props.extraProps };
  if (props.initialized === false) {
    extraProps.position = {
      at: 'center',
      my: 'center',
      of: window,
    };
  }
  return (
    <Popup
      onResizeEnd={props.onResizeEnd}
      onContentReady={(e: any) => {
        e.component.content().parentElement.classList.add('DxPopup');
      }}
      animation={{
        hide: {
          duration: 0,
          from: { position: { my: 'center', at: 'center', of: window } },
          to: { position: { my: 'center', at: 'center', of: window } },
          type: 'slide',
        },
        show: {
          duration: 0,
          from: { position: { my: 'center', at: 'center', of: window } },
          to: { position: { my: 'center', at: 'center', of: window } },
          type: 'slide',
        },
      }}
      onShown={() => {
        const deleteButton = document.getElementById('btnDelete');
        const confirmButton = document.getElementById('btnConfirm');
        if (deleteButton) {
          deleteButton.focus();
        }
        if (confirmButton) {
          confirmButton.focus();
        }
      }}
      defaultPosition={{
        at: 'center',
        my: 'center',
        of: window,
      }}
      visible={props.isModalOpen}
      resizeEnabled={true}
      defaultHeight={'auto'}
      minHeight={'170px'}
      maxHeight={'90vh'}
      defaultWidth={'auto'}
      minWidth={'200px'}
      maxWidth={'90vw'}
      shadingColor={'rgba(0,0,0,0.5)'}
      {...extraProps}
      titleComponent={() => props.modalHeader}
    >
      {props.children}
    </Popup>
  );
};

export default DraggableModal;
