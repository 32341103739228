import styled from 'styled-components';

export const MainContent = styled.div`
  width: 50%;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  background-color: white;
`;

export const LoadingContent = styled.div`
  top: 10;
  bottom: 10;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  background-color: #293a49;
`;

export const ActionContent = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  & .btn-primary {
    background-color: #0d83c9;
    color: #ffffff;
    text-transform: none;
    height: 28px;
    max-height: 28px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    box-shadow: none;
    &:hover {
      background-color: #22a0f4;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export const DisclaimerText = styled.p`
  font-size: 10px;
  padding: 10px;
`;

export const AppButton = styled.button`
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.14);
  color: white;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: none;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-top: 7px;
`;

export const HeaderMenu = styled.div`
  display: flex;
  background-color: #1c252e;
  height: 44px;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ErrorContent = styled.div`
  color: red;
  font-size: 16px;
`;
