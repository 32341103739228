import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { colors } from '@virtus/components/theme';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export interface IProps {
  value: string;
  size: string;
  onChange: (event: any, data: any) => void;
  children: (a: any, s: string, closeHandler: () => void) => React.ReactChild;
  style?: CSSProperties;
  disabled?: boolean;
}

export interface IState {
  visible: boolean;
  search: string;
}

const noop = () => null;

class Dropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      search: '',
      visible: false,
    };
  }
  public render() {
    return this.state.visible ? (
      <ClickAwayListener onClickAway={this.onClickAway}>{this.renderContents()}</ClickAwayListener>
    ) : (
      this.renderContents()
    );
  }

  private onClickAway = () => this.setState(() => ({ visible: false }));
  private open = () => this.setState(() => ({ visible: true }));
  private close = (e: any) => {
    const target = e.relatedTarget;
    if (target && target.attributes && target.attributes.role && target.attributes.role.value === 'showPortfolios') {
      return;
    }

    if (
      target &&
      target.attributes &&
      target.attributes.placeholder &&
      target.attributes.placeholder.value === 'Search...'
    ) {
      return;
    }

    this.setState(() => ({ visible: false }));
  };
  private setToActive = (e: React.ChangeEvent<HTMLDivElement>, value: any) => {
    if (!value) {
      return;
    }
    this.props.onChange(e, value);
    this.setState(() => ({ visible: false }));
  };

  private onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    this.setState((s: IState) => ({ ...s, search: val }));
  };

  private renderContents() {
    const visible = this.state.visible;
    return (
      <Wrapper style={this.props.style}>
        <Container
          onClick={visible || this.props.disabled ? noop : this.open}
          size={this.props.size}
          disabled={this.props.disabled}
        >
          {visible ? (
            <div role="search" style={{ width: '100%' }}>
              <Input
                placeholder="Search..."
                value={this.state.search}
                onChange={this.onSearch}
                data-testid="dropdown-input"
              />
            </div>
          ) : (
            <Text size="md" data-testid="dropdown-value">
              {this.props.value}
            </Text>
          )}
          {visible ? (
            <ArrowUp data-testid="close-button" onClick={this.close} />
          ) : (
            <ArrowDown data-testid="open-button" disabled={this.props.disabled} />
          )}
        </Container>
        <Menu visible={visible} size={this.props.size} data-testid="dropdown-menu">
          {this.props.children(this.setToActive, this.state.search, () =>
            this.setState({ ...this.state, visible: !this.state.visible }),
          )}
        </Menu>
      </Wrapper>
    );
  }
}

const Wrapper: any = styled.div`
  margin-left: 10px;
  margin-right: 12px;
  background: rgba(255, 255, 255, 0.14);
  color: #333;

  &:focus {
    outline: none;
  }
`;

const containerHeights: any = {
  sm: '25px',
  md: '28px',
  lg: '28px',
  xl: '40px',
};
const fontSizes: any = { sm: '12px', md: '14px', lg: '18px', xl: '24px' };

const Container: any = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  padding: 5px 10px;
  border-radius: 3px;
  width: 100%;
  height: ${(props: any) => containerHeights[props.size]};
`;

const Menu: any = styled.div`
  position: absolute;
  display: ${(props: any) => (props.visible ? 'block' : 'none')};
  height: 260px;
  overflow-y: auto;
  margin-top: 5px;
  z-index: 501;
  background: ${(props: any) => (props.visible ? colors.accentDarkBlue : 'transparent')};
  border-radius: 3px;
  box-shadow: ${(props: any) => (props.visible ? '0px 2px 2px rgba(128, 134, 136, 0.35)' : 'none')};
`;

const Text: any = styled.p`
  color: ${colors.text};
  font-size: ${(props: any) => fontSizes[props.size]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
`;

const ArrowDown: any = styled(ArrowDropDown)<{ disabled?: boolean }>`
  color: ${props => (props.disabled ? colors.grey : colors.text)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ArrowUp: any = styled(ArrowDropUp)`
  color: ${colors.text};
  cursor: pointer;
`;

const Input: any = styled(InputBase)`
  background: transparent;
  width: 100%;
  height: 45px;
  font-size: 14px;
  color: white;
  margin: 0;
  &:focus {
    outline: none;
    border: none !important;
  }
`;

export default Dropdown;
